import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    overflow: hidden;
    width: 725px;
    background-color: #F3F5F9;
    height: 400px;

    .content {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
    }

    .contentNav {
      display: flex;
      width: 730px;
      justify-content: space-between;
      align-items: flex-start;
    }

    .contentTitle {
      display: flex;
      padding: 16px 40px;
      flex-direction: column;
      align-items: flex-start;
      width: 605px;
      height: 90px;
      gap: var(--S, 8px);
      align-self: stretch;
      background-color: #3E6CFF;
      color: ${(props) => props.theme.branco};

      p {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; 
      }

      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 10px;
        margin-top: 10px;
      }

      button {
      background-color: #3E6CFF;
    }
    }

    h1 {
      font-size: 2.0rem;
      font-weight: 600;
      color: ${(props) => props.theme.grayDark2};
    }

    h3 {
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      color: ${(props) => props.theme.branco};
      cursor: pointer;
    }

    h5 {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
        margin-top: 1.0rem;
        color: ${(props) => props.theme.text};
    }

  
`;

export const Button = styled.button`
    background: none;
    border: none; 
    width: 4rem;
    cursor: pointer;
`;

export const ContentPatch = styled.div`
    overflow-y: scroll;
    overflow-x: hidden;
    height: 329px;
    margin-top: 27px;
    width: 710px;

 ::-webkit-scrollbar {
    width: 22px;
    height: 16px;
    top: 27px;
    transform: rotate(90deg);
    flex-shrink: 0;
    background-color:#E3E3E5;
  }

  ::-webkit-scrollbar-thumb { 
    background-color: ${(props) => props.theme.grayDark2}; 
  }
`;

export const Box = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.branco};
    color: ${(props) => props.theme.grayDark2}; 
    height: auto;
    padding: 10px 40px;
    margin-top: 4px;
    gap: 8px;
    width: 605px;

    span {
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
        font-size: 14px;
    }

    > p {
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 14px;
    }
`;

export const BoxNav = styled.div`  
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

> h3 {
      background-color: ${(props) => props.theme.branco};
      font-size: 1rem;
      font-weight: 600;
      line-height: 18px;
      color: ${(props) => props.theme.grayDark2};
}


`;
