import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as EditIcon } from "../../icons/edit.svg";
import { ReactComponent as CancelIcon } from "../../icons/cancel-icon.svg";
import Button from "../@DesignSystem/Button";
import ThomsonService from "../../services/ThomsonService";

import { store } from "react-notifications-component";
import { notificationTopRight, ToastNotification } from "../ToastNotification";
import FormInput from "../@DesignSystem/FormInput";
import { useInput } from "../../hooks/useInput";

const Credencial = styled.div`
  padding: 16px 20px;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  margin-top: 16px;
  background-color: #f8f9fa;
  max-width: 400px;
  position: relative;
  display: flex;

  div.wrapper {
    display: flex;
    flex-direction: column;
  }

  h4 {
    font-size: 1.25rem;
    font-weight: 600;
  }

  p {
    margin-top: 8px;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
  }

  button.edit {
    width: 40px;
    position: absolute;
    right: 0;
    cursor: pointer;
    top: 0;
    bottom: 0;
    background: #f8f9fa;
    color: ${(p) => p.theme.grayDark};
    border: none;
    transition: all 200ms ease;

    &:hover {
      background: ${(p) => p.theme.grayLight2};
      color: white;
    }
  }
`;

const CredencialThomson = ({ credencial, tokenLogin, getCredenciais }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [confirmaApagar, setConfirmaApagar] = useState(false);
  const [atualizaChave, setAtualizaChave] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    value: chaveThomsonValue,
    bind: bindChaveThomsonValue,
    reset: resetChaveThomsonValue,
    check: checkChaveThomson,
    isInvalid: invalidChaveThomson,
  } = useInput("", "");

  const apagarCredencial = () => {
    setLoading(true);
    console.log("Apagando credencial");
    //API call
    ThomsonService.deletarCredencialThomson(tokenLogin, credencial.nome)
      .then((res) => {
        console.log(res);
        setLoading(false);
        getCredenciais();
        store.addNotification({
          ...notificationTopRight,
          content: (
            <ToastNotification bg="primary" textoAuxiliar="">
              Credencial apagada com sucesso!
            </ToastNotification>
          ),
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const atualizarChave = () => {
    setLoading(true);
    console.log("Atualizando chave");
    //API call
    ThomsonService.atualizarSenhaCredenciais(
      tokenLogin,
      credencial.nome,
      chaveThomsonValue
    )
      .then((res) => {
        console.log(res);
        setLoading(false);
        getCredenciais();
        store.addNotification({
          ...notificationTopRight,
          content: (
            <ToastNotification bg="primary" textoAuxiliar="">
              Chave atualizada com sucesso!
            </ToastNotification>
          ),
        });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        store.addNotification({
          ...notificationTopRight,
          content: (
            <ToastNotification
              bg="danger"
              textoAuxiliar="Tente novamente mais tarde"
            >
              Erro ao atualizar chave da credencial
            </ToastNotification>
          ),
        });
      });
  };

  return (
    <Credencial key={credencial.id}>
      {confirmaApagar ? (
        <div className="wrapper">
          <p>
            Tem certeza que deseja apagar <b>{credencial.nome}</b> ?
          </p>
          <div className="buttons">
            <Button
              secondary
              bg="grayLight"
              color="text"
              onClick={() => setConfirmaApagar(false)}
            >
              Cancelar
            </Button>
            <Button
              secondary
              bg="dangerLight"
              color="danger"
              onClick={() => {
                if (!loading) {
                  apagarCredencial();
                }
              }}
            >
              Apagar
            </Button>
          </div>
        </div>
      ) : atualizaChave ? (
        <div className="wrapper">
          <h4>{credencial.nome}</h4>
          <FormInput
            required
            mt={32}
            width={320}
            label="Nova chave"
            error={invalidChaveThomson}
            type="password"
            errorMsg=""
            {...bindChaveThomsonValue}
          />
          <div className="buttons">
            <Button
              secondary
              bg="grayLight"
              color="text"
              onClick={() => setAtualizaChave(false)}
            >
              Cancelar
            </Button>
            <Button
              size="small"
              onClick={() => {
                checkChaveThomson();
                if (!invalidChaveThomson && chaveThomsonValue && !loading) {
                  atualizarChave();
                }
              }}
            >
              Atualizar chave
            </Button>
          </div>
        </div>
      ) : (
        <div className="wrapper">
          <h4>{credencial.nome}</h4>
          <p>{credencial.usuario_api}</p>
          {isEditing && (
            <div className="buttons">
              <Button
                secondary
                size="small"
                fontSize={14}
                color="danger"
                bg="dangerLight"
                onClick={() => setConfirmaApagar(true)}
              >
                Apagar credencial
              </Button>
              <Button fontSize={14} onClick={() => setAtualizaChave(true)}>
                Atualizar chave
              </Button>
            </div>
          )}
        </div>
      )}
      <button
        className="edit"
        onClick={() => {
          setIsEditing(!isEditing);
          setConfirmaApagar(false);
          setAtualizaChave(false);
        }}
      >
        {isEditing ? <CancelIcon /> : <EditIcon />}
      </button>
    </Credencial>
  );
};

export default CredencialThomson;
