import React from "react";
import { GlobalStyle, themeDefault } from "./theme/GlobalStyle";
import styled, { ThemeProvider } from "styled-components/macro";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import ReactNotifications from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import UserContext from "./context/user-context";
import BancoContext from "./context/banco-context";

// import { HomeContent } from './components/home/HomeContent'
import { UploadContent } from "./components/upload/UploadContent";
import Topnav from "./components/Topnav";
import { SidenavWithRouter } from "./components/sidenav/Sidenav.js";
// import { FooterPBIPartner } from "./components/landing-page/FooterPBIPartner";
import { Configuracoes } from "./components/configuracoes/Configuracoes";
import Dashboard from "./components/dashboard/Dashboard";
import Manutencao from "./components/Manutencao";
import { Vendas } from "./components/Adm/Vendas";
import { Usuarios } from "./components/Adm/Usuarios";
import { Aplicacao } from "./components/Adm/Aplicacao";
import Suporte from "./components/suporte/index";
//import RecuperaSenha from "./components/landing-page/RecuperaSenha"
import BancoService from "./services/BancoService";
// import FileProgress from "./components/FileProgress";
// import { CSSTransition } from "react-transition-group";
import { store } from "react-notifications-component";
import "animate.css";
import {
  ToastNotification,
  notificationTopRight,
} from "./components/ToastNotification";

import Page404 from "./404";
import UsuarioService from "./services/UsuarioService";
import NotasAtt from "./components/configuracoes/NotasAtt.js";
import Patch from "./components/PatchNotes/Patch.js";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Content = styled.div`
  width: calc(100% - 216px);
  padding: 40px;
  min-height: calc(100vh - 56px);
  transition: all 0.3s;
  position: absolute;
  top: 56px;
  right: 0;
  box-sizing: border-box;
  transition: all 0.3s ease;

  &.collapsed {
    width: calc(100% - 50px);
  }
`;

// const Progress = styled.div`
//   position:  fixed;
//   top: 0;
//   background: linear-gradient(
//     to right,
//     rgba(21, 107, 192, 1) ${props => props.progress},
//     #003c8f  0);
//   width:  100%;
//   height:  4px;
//   z-index: 1001;
// `

// const FloatButtons = styled.div`
//   position: fixed;
//   bottom: 20px;
//   right: 25px;
//   z-index: 102;
//   display: grid;
//   grid-template-columns: 1fr;
//   grid-gap: 1em;
//   justify-items: end;
// `;

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tipoArquivo: "",
      showUploadDropdown: false,
      isCollapsed: false,
      usuario: props.usuario,
      temOperacao: true,
      operacaoEmAndamento: "",
      isLeitor: false,
      permissao: "",
      temBanco: false,
      emManutencao: false,
      bancoUsuario: "banco",
      showDashboard: false,
      showUploadContent: false,
      ehTeste: false,
      ehSuporte: false,
      ehCs: false,
      planoSynapse: false
      // progress: 0,
      // isWorking: false,
    };

    this.handleCollapseSidenav = this.handleCollapseSidenav.bind(this);
    this.handleShowDropdown = this.handleShowDropdown.bind(this);
    this.checkOperacao = this.checkOperacao.bind(this);
  }

  componentDidMount() {
    //checka manutencao

    // this.setState({
    //   emManutencao: true
    // })

    if (!this.state.usuario && this.props.tokenLogin) {
      UsuarioService.validarTokenLogin(this.props.tokenLogin)
        .then((res) => {
          this.setState({ usuario: res.data.email });
        })
        .catch((err) => {
          //console.log(err);
        });
    }
    UsuarioService.verficaPermissao(this.props.tokenLogin)
      .then((resp) => {
        // console.log(resp.data);
        this.setState(
          {
            permissao: resp.data.permissao,
            temBanco: resp.data.temBanco,
            bancoUsuario: resp.data.id_banco,
            showDashboard: true,
            showUploadContent: true,
            ehTeste: resp.data.ehTeste,
            ehSuporte: resp.data.ehSuporte,
            ehCs: resp.data.ehCs,
            planoSynapse: resp.data.plano_synapse
          },
          () => {
            this.checkOperacao();
          }
        );
        if (resp.data.podeEscrever === false) {
          this.setState({
            isLeitor: true,
            isCollapsed: true,
            permissao: resp.data.permissao,
          });
        }

        if (resp.data.permissao === "contato") {
          this.setState({
            isContato: true,
          });
        }
      })
      .catch((err) => {
        //console.log(err);
      });
    // BancoService.checaOperacaoEmAndamento(this.props.tokenLogin)
    //   .then((resp) => {
    //     //console.log(resp);
    //     if (resp.data.temOperacao) {
    //       this.setState({
    //         temOperacao: true,
    //         operacaoEmAndamento: resp.data.tipoOperacao,
    //       });
    //       //console.log("com func");
    //     } else {
    //       this.setState({
    //         temOperacao: false,
    //       });
    //       //console.log("sem func");
    //     }
    //   })
    //   .catch((err) => {
    //     //console.log("erro");
    //   });
    // if (window.checkOper) {
    //   clearInterval(window.checkOper);
    // }
    // window.checkOper = setInterval(this.checkOperacaoTeste, 5000);

    let count = 0;
    let nome = this.props.usuario;
    let email = this.state.usuario;
    let ConpassInterval = window.setInterval(function () {
      if (window.Conpass) {
        window.Conpass.init({
          name: nome,
          email: email,
          // Informações opcionais (usado para segmentação)
          // custom_fields: {
          //   lang: "pt_BR",
          //   sexo: "Feminino",
          // }
        });
        // console.dir(window.Conpass);
        if (window.Conpass || count >= 100) clearInterval(ConpassInterval);
      }
      count += 1;
    }, 100);
  }

  //upload/zip

  checkOperacaoTeste(customTimeout) {
    let ms = customTimeout ? customTimeout : 5000;
    // console.log("--------------------checkOperacao");
    try {
      BancoService.checaOperacaoEmAndamento(this.props.tokenLogin).then(
        (resp) => {
          if (resp.data.temOperacao) {
            this.setState({
              temOperacao: true,
              operacaoEmAndamento: resp.data.tipoOperacao,
            });
          }
        }
      );
      // //console.log(this);
      // //console.log(typeof(this));
      let resultadoOper = BancoService.checaOperacaoEmAndamento(
        this.props.tokenLogin
      );
      // resultadoOper.data.tipoOperacao
      // console.log(resultadoOper);
      if (resultadoOper.data.temOperacao) {
        this.checkOperacao();
        // console.log("/////////////2 vez checkOperacao");
        this.setState({
          temOperacao: true,
          operacaoEmAndamento: resultadoOper.data.tipoOperacao,
        });
        //console.log("com func");
      } else {
        // clearTimeout(timeout);
        this.setState({
          temOperacao: false,
        });
        // console.log(this.state.operacaoEmAndamento);
        if (
          this.state.operacaoEmAndamento &&
          this.state.operacaoEmAndamento.toLowerCase() !==
          "arquivos/get/importar"
        ) {
          store.addNotification({
            content: (
              <ToastNotification bg="primary" textoAuxiliar="">
                {this.state.operacaoEmAndamento.startsWith("post/arquivos") ||
                  this.state.operacaoEmAndamento.startsWith(
                    "arquivos/post/arquivo"
                  )
                  ? "Arquivos carregados com sucesso!"
                  : this.state.operacaoEmAndamento.startsWith(
                    "moduloarquivos/delete/comum/arquivos"
                  ) ||
                    this.state.operacaoEmAndamento.startsWith("delete/arquivos")
                    ? "Arquivos apagados com sucesso!"
                    : this.state.operacaoEmAndamento === "powerbi/post/refresh"
                      ? "Dashboard atualizado com sucesso!"
                      : this.state.operacaoEmAndamento.startsWith("upload/zip")
                        ? "Arquivos descompactados com sucesso!"
                        : this.state.operacaoEmAndamento.toLowerCase() ===
                          "arquivos/get/importar"
                          ? "Importação finalizada!"
                          : this.state.operacaoEmAndamento}
              </ToastNotification>
            ),
            //onRemoval: (id, removedBy) => //console.log(removedBy),
            ...notificationTopRight,
          });
        }
        if (this.state.operacaoEmAndamento === "powerbi/post/refresh") {
          this.refreshPbiIframe();
        }
      }
    } catch (error) {
      //  console.log("erro");
      // console.log(error);
      this.checkOperacao();
    }
  }

  async checkOperacao(customTimeout) {
    if (!this.state.temBanco) {
      this.setState({
        temOperacao: false,
      });
      return;
    }
    let ms = customTimeout ? customTimeout : 3000;
    if (window.temCheckOperacao) {
      // console.log("chamada checkOper duplicada");
      // return;
    }
    //console.log("--------------------checkOperacao");
    let timeout = await setTimeout(async () => {
      try {
        window.temCheckOperacao = true;
        // //console.log(this);
        // //console.log(typeof(this));
        let resultadoOper = await BancoService.checaOperacaoEmAndamento(
          this.props.tokenLogin
        );
        // resultadoOper.data.tipoOperacao
        //console.log(resultadoOper);
        if (resultadoOper.data.temOperacao) {
          this.checkOperacao();
          this.setState({
            temOperacao: true,
            operacaoEmAndamento: resultadoOper.data.tipoOperacao,
          });
          //console.log("com func");
        } else {
          clearTimeout(timeout);
          window.temCheckOperacao = false;
          this.setState({
            temOperacao: false,
          });
          //  console.log(this.state.operacaoEmAndamento);
          if (
            this.state.operacaoEmAndamento &&
            this.state.operacaoEmAndamento.toLowerCase() !==
            "arquivos/get/importar"
          ) {
            store.addNotification({
              content: (
                <ToastNotification bg="primary" textoAuxiliar="">
                  Operação finalizada!
                </ToastNotification>
              ),
              //onRemoval: (id, removedBy) => //console.log(removedBy),
              ...notificationTopRight,
            });
          }
          if (this.state.operacaoEmAndamento === "powerbi/post/refresh") {
            this.refreshPbiIframe();
          }
        }
      } catch (error) {
        //console.log("erro");
        //console.log(error);
        this.checkOperacao();
      }
    }, ms);
  }

  refreshPbiIframe = () => {
    try {
      document.querySelector(".pbi-iframe iframe").src =
        document.querySelector(".pbi-iframe iframe").src;
    } catch (err) {
      // console.log(err);
    }
  };

  //   // emulating progress
  //   this.setState({
  //     isWorking: true,
  //   });
  //   // const interval = setInterval(() => {
  //   //   this.setState({ progress: this.state.progress + 10 });
  //   //   if (this.state.progress === 100)
  //   //     clearInterval(interval);
  //   // }, 1000);
  // }

  handleShowDropdown() {
    this.setState({
      showUploadDropdown: !this.state.showUploadDropdown,
      isCollapsed: false,
    });
  }

  handleCollapseSidenav() {
    this.setState({
      showUploadDropdown: false,
      isCollapsed: !this.state.isCollapsed,
    });
  }

  render() {
    const reinf = [
      "1000",
      "1050",
      "1070",
      "2010",
      "2020",
      "2030",
      "2040",
      "2050",
      "2055",
      "2060",
      "2098",
      "2099",
      "3010",
      "4010",
      "4020",
      "4040",
      "4080",
      "4099",
      "9000",
      "9001",
      "9005",
      "9011",
    ];

    const esocial = [
      "1000",
      "1005",
      "1010",
      "1020",
      "1030",
      "1035",
      "1040",
      "1050",
      "1060",
      "1070",
      "1080",
      "1200",
      "1202",
      "1207",
      "1210",
      "1250",
      "1260",
      "1270",
      "1280",
      "1295",
      "1298",
      "1299",
      "1300",
      "2190",
      "2200",
      "2205",
      "2206",
      "2210",
      "2220",
      "2221",
      "2230",
      "2231",
      "2240",
      "2245",
      "2250",
      "2260",
      "2298",
      "2299",
      "2300",
      "2306",
      "2399",
      "2400",
      "2405",
      "2410",
      "2416",
      "2418",
      "2420",
      "2500",
      "2501",
      "3000",
      "5001",
      "5002",
      "5003",
      "5011",
      "5012",
      "5013",
      "5501",
      "8299",
    ];

    return (
      <UserContext.Provider value={this.state.permissao}>
        <BancoContext.Provider value={this.state.temBanco}>
          <ThemeProvider theme={themeDefault}>
            <div className="App">
              <GlobalStyle />
              <ReactNotifications />

              <Router>
                {!this.state.emManutencao && (
                  <Topnav
                    usuario={this.state.usuario}
                    tokenLogin={this.props.tokenLogin}
                    doLogin={this.props.doLogin}
                    temOperacao={this.state.temOperacao}
                    operacaoEmAndamento={this.state.operacaoEmAndamento}
                    isLeitor={this.state.isLeitor}
                    isContato={this.state.isContato}
                    toggleAdm={this.props.toggleAdm}
                  />
                )}
                {/* <FloatButtons>
                <CSSTransition in={this.state.isWorking} timeout={800} classNames="slide-up" unmountOnExit>
                  <FileProgress progress={50} texto="Carregando arquivos NFe Entrada">
                  </FileProgress>
                </CSSTransition>
              </FloatButtons> */}
                <Wrapper>
                  {!this.state.emManutencao && (
                    <SidenavWithRouter
                      handleShowDropdown={this.handleShowDropdown}
                      handleCollapseSidenav={this.handleCollapseSidenav}
                      doLogin={this.props.doLogin}
                      isCollapsed={this.state.isCollapsed}
                      showDropdown={this.state.showUploadDropdown}
                      isAdm={this.props.isAdm}
                      isLeitor={this.state.isLeitor}
                      ehSuporte={this.state.ehSuporte}
                      ehCs={this.state.ehCs}
                    />
                  )}
                  <Content className={this.state.isCollapsed && "collapsed"}>
                    <Switch>
                      {this.state.emManutencao && (
                        <Route
                          path="/"
                          render={(props) => (
                            <Manutencao
                              {...props}
                              marginNeg={true}
                              usuario={this.state.usuario}
                              tokenLogin={this.props.tokenLogin}
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                            />
                          )}
                        />
                      )}
                      <Route
                        path="/manutencao"
                        render={(props) => (
                          <Manutencao
                            {...props}
                            marginNeg={false}
                            usuario={this.state.usuario}
                            tokenLogin={this.props.tokenLogin}
                            temOperacao={this.state.temOperacao}
                            operacaoEmAndamento={this.state.operacaoEmAndamento}
                            checkOperacao={this.checkOperacao}
                            isLeitor={this.state.isLeitor}
                          />
                        )}
                      />
                      <Route
                        path="/configuracoes"
                        render={(props) => (
                          <Configuracoes
                            {...props}
                            usuario={this.state.usuario}
                            tokenLogin={this.props.tokenLogin}
                            temOperacao={this.state.temOperacao}
                            operacaoEmAndamento={this.state.operacaoEmAndamento}
                            checkOperacao={this.checkOperacao}
                            isLeitor={this.state.isLeitor}
                            planoSynapse={this.state.planoSynapse}
                          />
                        )}
                      />
                      <Route
                        path="/notas"
                        render={(props) => (
                          <Patch
                            {...props}
                            usuario={this.state.usuario}
                            tokenLogin={this.props.tokenLogin}
                            temOperacao={this.state.temOperacao}
                            operacaoEmAndamento={this.state.operacaoEmAndamento}
                            checkOperacao={this.checkOperacao}
                            isLeitor={this.state.isLeitor}
                          />
                        )}
                      />
                      {this.state.ehSuporte && (
                        <Route
                          path="/suporte"
                          render={(props) => (
                            <Suporte
                              tokenLogin={this.props.tokenLogin}
                            ></Suporte>
                          )}
                        />
                      )}
                       {(this.state.ehSuporte || this.state.ehCs) && (
                        <Route
                          path="/historico"
                          render={(props) => (
                            <Suporte
                              tokenLogin={this.props.tokenLogin}
                              historico={true}
                            ></Suporte>
                          )}
                        />
                      )}
                      <Route
                        path="/dashboard/:source"
                        render={(props) => {
                          // if (this.props.isAdm) {
                          //   return <Redirect to="/adm/vendas" />;
                          // }
                          return (
                            this.state.showDashboard && (
                              <Dashboard
                                {...props}
                                usuario={this.state.usuario}
                                tokenLogin={this.props.tokenLogin}
                                temOperacao={this.state.temOperacao}
                                operacaoEmAndamento={
                                  this.state.operacaoEmAndamento
                                }
                                checkOperacao={this.checkOperacao}
                                temBanco={this.state.temBanco}
                                isLeitor={this.state.isLeitor}
                                isTeste={this.state.ehTeste}
                                notHome
                              />
                            )
                          );
                        }}
                      />
                      <Route
                        path="/dashboard/"
                        render={(props) => {
                          // if (this.props.isAdm) {
                          //   return <Redirect to="/adm/vendas" />;
                          // }
                          return (
                            this.state.showDashboard && (
                              <Dashboard
                                {...props}
                                usuario={this.state.usuario}
                                tokenLogin={this.props.tokenLogin}
                                temOperacao={this.state.temOperacao}
                                operacaoEmAndamento={
                                  this.state.operacaoEmAndamento
                                }
                                checkOperacao={this.checkOperacao}
                                temBanco={this.state.temBanco}
                                isLeitor={this.state.isLeitor}
                                isTeste={this.state.ehTeste}
                              />
                            )
                          );
                        }}
                      />
                      {/* --------------------------------------- Storage -------------------------------------------------------------------------------------------------- */}
                      <Route
                        path="/storage/icms"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="EFD ICMS-IPI"
                              arquivo="ICMS"
                              banco="storage"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                            />
                          )
                        }
                      />
                      <Route
                        path="/storage/contrib"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="EFD Contribuições"
                              arquivo="CONTRIB"
                              banco="storage"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                            />
                          )
                        }
                      />
                      <Route
                        path="/storage/ecf"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="ECF"
                              arquivo="ECF"
                              banco="storage"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                            />
                          )
                        }
                      />
                      <Route
                        path="/storage/contabil"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="ECD"
                              arquivo="CONTABIL"
                              banco="storage"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                            />
                          )
                        }
                      />
                      <Route
                        path="/storage/perdcomp"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="PERDCOMP"
                              arquivo="PERDCOMP"
                              banco="storage"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                            />
                          )
                        }
                      />
                      <Route
                        path="/storage/gia"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="GIA"
                              arquivo="GIA"
                              banco="storage"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                            />
                          )
                        }
                      />
                      <Route
                        path="/storage/dctf"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="DCTF"
                              arquivo="DCTF"
                              banco="storage"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                            />
                          )
                        }
                      />
                      <Route
                        path="/storage/cat42"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="CAT42"
                              arquivo="CAT42"
                              banco="storage"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                            />
                          )
                        }
                      />
                      <Route
                        path="/storage/cat207"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="CAT207"
                              arquivo="CAT207"
                              banco="storage"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                            />
                          )
                        }
                      />
                      <Route
                        path="/storage/cat83"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="CAT83"
                              arquivo="CAT83"
                              banco="storage"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                            />
                          )
                        }
                      />
                      <Route
                        path="/storage/nfe_entrada"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="NF-e Entrada"
                              arquivo="NFE_ENTRADA"
                              banco="storage"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                              isXML
                            />
                          )
                        }
                      />
                      <Route
                        path="/storage/nfe_saida"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="NF-e Saída/NFCe"
                              arquivo="NFE_SAIDA"
                              banco="storage"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                              isXML
                            />
                          )
                        }
                      />
                      <Route
                        path="/storage/evento_nfe"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="Evento NFe"
                              arquivo="EVENTO_NFE"
                              banco="storage"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                              isXML
                            />
                          )
                        }
                      />
                      <Route
                        path="/storage/nfse"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="NFSe"
                              arquivo="NFSE"
                              banco="storage"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                              isXML
                            />
                          )
                        }
                      />
                      <Route
                        path="/storage/cte"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tipoArquivo="CT-e"
                              arquivo="CTE"
                              tokenLogin={this.props.tokenLogin}
                              banco="storage"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                              isXML
                            />
                          )
                        }
                      />
                      <Route
                        path="/storage/cfe"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tipoArquivo="CF-e"
                              arquivo="CFE"
                              tokenLogin={this.props.tokenLogin}
                              banco="storage"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                              isXML
                            />
                          )
                        }
                      />
                      {/* <Route
                        path="/storage/safpt"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tipoArquivo="SAF-T PT"
                              arquivo="SAFPT"
                              tokenLogin={this.props.tokenLogin}
                              banco="storage"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                              isXML
                            />
                          )
                        }
                      /> */}
                      {reinf.map((item) => (
                        <Route
                          path={`/storage/reinf_${item}`}
                          render={(props) =>
                            this.state.showUploadContent && (
                              <UploadContent
                                {...props}
                                tipoArquivo={`REINF ${item}`}
                                arquivo={`REINF_${item}`}
                                tokenLogin={this.props.tokenLogin}
                                banco="storage"
                                temOperacao={this.state.temOperacao}
                                operacaoEmAndamento={
                                  this.state.operacaoEmAndamento
                                }
                                checkOperacao={this.checkOperacao}
                                isLeitor={this.state.isLeitor}
                              />
                            )
                          }
                        />
                      ))}
                      {esocial.map((item) => (
                        <Route
                          path={`/storage/esocial_${item}`}
                          render={(props) =>
                            this.state.showUploadContent && (
                              <UploadContent
                                {...props}
                                tipoArquivo={`eSocial ${item}`}
                                arquivo={`ESOCIAL_${item}`}
                                tokenLogin={this.props.tokenLogin}
                                banco="storage"
                                temOperacao={this.state.temOperacao}
                                operacaoEmAndamento={
                                  this.state.operacaoEmAndamento
                                }
                                checkOperacao={this.checkOperacao}
                                isLeitor={this.state.isLeitor}
                              />
                            )
                          }
                        />
                      ))}
                      {/* --------------------------------------- Banco de dados -------------------------------------------------------------------------------------------------- */}
                      <Route
                        path="/database/icms"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="EFD ICMS-IPI"
                              arquivo="ICMS"
                              banco="database"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                            />
                          )
                        }
                      />
                      <Route
                        path="/database/contrib"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="EFD Contribuições"
                              arquivo="CONTRIB"
                              banco="database"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                            />
                          )
                        }
                      />
                      <Route
                        path="/database/ecf"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="ECF"
                              arquivo="ECF"
                              banco="database"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                            />
                          )
                        }
                      />
                      <Route
                        path="/database/contabil"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="ECD"
                              arquivo="CONTABIL"
                              banco="database"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                            />
                          )
                        }
                      />
                      <Route
                        path="/database/perdcomp"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="PERDCOMP"
                              arquivo="PERDCOMP"
                              banco="database"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                            />
                          )
                        }
                      />
                      <Route
                        path="/database/gia"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="GIA"
                              arquivo="GIA"
                              banco="database"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                            />
                          )
                        }
                      />
                      <Route
                        path="/database/dctf"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="DCTF"
                              arquivo="DCTF"
                              banco="database"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                            />
                          )
                        }
                      />
                      <Route
                        path="/database/cat42"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="CAT42"
                              arquivo="CAT42"
                              banco="database"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                            />
                          )
                        }
                      />
                      <Route
                        path="/database/cat207"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="CAT207"
                              arquivo="CAT207"
                              banco="database"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                            />
                          )
                        }
                      />
                      <Route
                        path="/database/cat83"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="CAT83"
                              arquivo="CAT83"
                              banco="database"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                            />
                          )
                        }
                      />
                      <Route
                        path="/database/nfe_entrada"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="NF-e Entrada"
                              arquivo="NFE_ENTRADA"
                              banco="database"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                              isXML
                            />
                          )
                        }
                      />
                      <Route
                        path="/database/nfe_saida"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="NF-e Saída/NFCe"
                              arquivo="NFE_SAIDA"
                              banco="database"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                              isXML
                            />
                          )
                        }
                      />
                      <Route
                        path="/database/evento_nfe"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="Evento NFe"
                              arquivo="evento_nfe"
                              banco="database"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                              isXML
                            />
                          )
                        }
                      />
                      <Route
                        path="/database/nfse"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tokenLogin={this.props.tokenLogin}
                              tipoArquivo="NFSe"
                              arquivo="NFSE"
                              banco="database"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                              isXML
                            />
                          )
                        }
                      />
                      <Route
                        path="/database/cte"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tipoArquivo="CT-e"
                              arquivo="CTE"
                              tokenLogin={this.props.tokenLogin}
                              banco="database"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                              isXML
                            />
                          )
                        }
                      />
                      <Route
                        path="/database/cfe"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tipoArquivo="CF-e"
                              arquivo="CFE"
                              tokenLogin={this.props.tokenLogin}
                              banco="database"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                              isXML
                            />
                          )
                        }
                      />
                       {/* <Route
                        path="/database/safpt"
                        render={(props) =>
                          this.state.showUploadContent && (
                            <UploadContent
                              {...props}
                              tipoArquivo="SAF-T PT"
                              arquivo="SAFPT"
                              tokenLogin={this.props.tokenLogin}
                              banco="database"
                              temOperacao={this.state.temOperacao}
                              operacaoEmAndamento={
                                this.state.operacaoEmAndamento
                              }
                              checkOperacao={this.checkOperacao}
                              isLeitor={this.state.isLeitor}
                              isXML
                            />
                          )
                        }
                      /> */}
                      {reinf.map((item) => (
                        <Route
                          path={`/database/reinf_${item}`}
                          render={(props) =>
                            this.state.showUploadContent && (
                              <UploadContent
                                {...props}
                                tipoArquivo={`REINF ${item}`}
                                arquivo={`REINF_${item}`}
                                tokenLogin={this.props.tokenLogin}
                                banco="database"
                                temOperacao={this.state.temOperacao}
                                operacaoEmAndamento={
                                  this.state.operacaoEmAndamento
                                }
                                checkOperacao={this.checkOperacao}
                                isLeitor={this.state.isLeitor}
                              />
                            )
                          }
                        />
                      ))}
                      {esocial.map((item) => (
                        <Route
                          path={`/database/esocial_${item}`}
                          render={(props) =>
                            this.state.showUploadContent && (
                              <UploadContent
                                {...props}
                                tipoArquivo={`eSocial ${item}`}
                                arquivo={`ESOCIAL_${item}`}
                                tokenLogin={this.props.tokenLogin}
                                banco="database"
                                temOperacao={this.state.temOperacao}
                                operacaoEmAndamento={
                                  this.state.operacaoEmAndamento
                                }
                                checkOperacao={this.checkOperacao}
                                isLeitor={this.state.isLeitor}
                              />
                            )
                          }
                        />
                      ))}
                      {/* --------------------------------------- ADM -------------------------------------------------------------------------------------------------- */}
                      {!this.props.isAdm && (
                        <Route
                          path="/adm"
                          render={() => <Redirect to="/dashboard" />}
                        />
                      )}
                      <Route
                        path="/adm/vendas/:idVendaUrl"
                        render={(props) => (
                          <Vendas
                            {...props}
                            usuario={this.state.usuario}
                            tokenLogin={this.props.tokenLogin}
                          />
                        )}
                      />
                      <Route
                        path="/adm/vendas/"
                        exact
                        render={(props) => (
                          <Vendas
                            {...props}
                            isVendas={true}
                            usuario={this.state.usuario}
                            tokenLogin={this.props.tokenLogin}
                          />
                        )}
                      />
                      <Route
                        path="/adm/testes/"
                        exact
                        render={(props) => (
                          <Vendas
                            {...props}
                            isVendas={false}
                            usuario={this.state.usuario}
                            tokenLogin={this.props.tokenLogin}
                          />
                        )}
                      />
                      <Route
                        path="/adm/usuarios/:id"
                        render={(props) => (
                          <Usuarios
                            {...props}
                            usuario={this.state.usuario}
                            tokenLogin={this.props.tokenLogin}
                          />
                        )}
                      />
                      <Route
                        path="/adm/usuarios/"
                        exact
                        render={(props) => (
                          <Usuarios
                            {...props}
                            usuario={this.state.usuario}
                            tokenLogin={this.props.tokenLogin}
                          />
                        )}
                      />
                      {this.state.permissao === "dev" && (
                        <Route
                          path="/adm/app"
                          render={(props) => (
                            <Aplicacao
                              {...props}
                              usuario={this.state.usuario}
                              tokenLogin={this.props.tokenLogin}
                            />
                          )}
                        />
                      )}
                      {this.state.permissao === "suporte" && (
                        <Route
                          path="/adm/suporte"
                          render={(props) => (
                            <Suporte
                              {...props}
                              usuario={this.state.usuario}
                              tokenLogin={this.props.tokenLogin}
                            />
                          )}
                        />
                      )}
                      {/* <Route path="/" exact render={(props) => (<HomeContent {...props} usuario={this.state.usuario} />)} /> */}
                      <Route
                        path="/"
                        exact
                        render={() => {
                          // console.log(this.props.isAdm);
                          if (this.state.permissao === "vendedor")
                            return <Redirect to="/adm/vendas" />;
                          if (this.state.permissao === "dev")
                            return <Redirect to="/adm/dev" />;
                          if (this.state.permissao === "suporte")
                            return <Redirect to="/adm/suporte" />;
                          return <Redirect to="/storage/icms" />;
                        }}
                      />
                      <Route>
                        <Page404 />
                      </Route>
                    </Switch>
                    {/* <FooterPBIPartner /> */}
                  </Content>
                </Wrapper>
              </Router>
            </div>
          </ThemeProvider>
        </BancoContext.Provider>
      </UserContext.Provider>
    );
  }
}

export default App;
