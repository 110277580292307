import { id } from "date-fns/locale";
import api from "./config/Axios";

export default class ThomsonService {
  static async getCredenciais(token) {
    return api({
      method: "get",
      url: `/credenciais_thomson/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static async criarCredencial(token, nome, chave, usuario_api) {
    return api({
      method: "post",
      url: `/credenciais_thomson/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        usuario_api: usuario_api,
        nome: nome,
        chave: chave,
      },
    });
  }

  static async atualizarSenhaCredenciais(token, nome, chave) {
    return api({
      method: "patch",
      url: `/credenciais_thomson/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        nome: nome,
        chave: chave,
      },
    });
  }

  static async deletarCredencialThomson(token, nome) {
    return api({
      method: "delete",
      url: `/credenciais_thomson/`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        nome: nome,
      },
    });
  }
}
