import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ArquivosService from "../../services/ArquivosService";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Button from "../@DesignSystem/Button";
import Dropdown from "../@DesignSystem/Dropdown";
import moment from "moment";
import { ReactComponent as AddIcon } from "../../icons/add-icon.svg"
import ThomsonService from "../../services/ThomsonService";
import { Input } from "../@DesignSystem/Input/style";
import InputMask from "react-input-mask";

const Wrapper = styled.div`
  p.legenda {
    font-size: 14px;
    color: ${(p) => p.theme.textDarkGray};
    margin-top: 32px;
  }

  .notFound {
    padding: 16px 20px;
    border: 1px solid #e9ecef;
    border-radius: 4px;
    margin-top: 32px;
    background-color: #f8f9fa;

    p.info {
      font-size: 16px;
      margin-top: 64px;
      padding: 0 48px;
      text-align: center;
    }
  }

  .upload {
    min-height: 320px;
    padding: 20px 20px;
    border: 1px solid #e9ecef;
    border-radius: 4px;
    margin-top: 32px;
    background-color: #f8f9fa;

    p.step {
      margin-top: 48px;
      font-size: 16px;
      margin-bottom: 16px;
      font-weight: 600;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

   svg {
    color: ${(p) => p.theme.textGray};
    padding-left: 15px;
  }
`;

const DateDiv = styled.div`
  display: flex;
  gap: 32px;
`;

const DateWrapper = styled.div`
  label {
    display: block;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 2px;
    color: ${(p) => p.theme.text};
  }

  &:focus-within {
    label {
      color: ${(p) => p.theme.primary};
    }
  }

  input[type="date"] {
    display: block;
    padding: 4px 8px;
    border-radius: 0 8px 0 8px;
    font-size: 16px;
    border: 2px solid ${(p) => p.theme.input.border};

    &:active {
      border: 2px solid ${(p) => p.theme.primary};
      outline-color: ${(p) => p.theme.primary};
    }

    &:focus {
      border: 2px solid ${(p) => p.theme.primary};
      outline-color: ${(p) => p.theme.primary};
    }
  }
`;

const StyledInputMask = styled(InputMask)`
  color: ${(p) => p.theme.text};
  font-size: 18px;
  margin-bottom: 4px;
  margin-top: 15px;
  border-radius: 0 12px 0 12px;
  border: 2px solid #dcdee0;
  height: 100%;
  background: rgba(220, 222, 224, 0);
  font-weight: 600;
  width: 297px;
  height: 34px;
  padding-left: 18px;

  &::placeholder {
    color: ${(p) => p.theme.textGray}
  }
`;

const UploadComThomson = ({
  tokenLogin,
  arquivo,
  toggleUploadModal,
  checkOperacao,
}) => {
  const history = useHistory();
  const [credenciais, setCredenciais] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCredencial, setSelectedCredencial] = useState(null);
  const [dataIni, setDataIni] = useState(null);
  const [dataFim, setDataFim] = useState(null);
  const [cnpj, setCnpj] = useState([]);
  const [ie, setIe] = useState([]);

  const getCredenciais = () => {
    setIsLoading(true);
    // API call
    ThomsonService.getCredenciais(tokenLogin)
      .then((response) => {
        console.log(response);
        setCredenciais(response.data.credenciais);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setCredenciais([]);
        setIsLoading(false);
      });
  };

  const handleDateChange = (e) => {
    let date = moment(e.target.value, "YYYY-MM-DD").format("DD/MM/YYYY");
    if (e.target.name === "dtIni") {
      setDataIni(date);
    } else if (e.target.name === "dtFim") {
      setDataFim(date);
    }
  };

  const carregarArquivos = () => {
    console.log(dataIni, dataFim, selectedCredencial, arquivo, tokenLogin, cnpj, ie);
    ArquivosService.importarArquivosThomson(
      dataIni + "00:00",
      dataFim + "23:59",
      selectedCredencial.value,
      arquivo,
      tokenLogin,
      cnpj,
      ie
    )
      .then((res) => {
        console.log(res);
        checkOperacao();
        toggleUploadModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCredenciais();
  }, []);

  const handleSetCnpj = (cnpj) => {
    console.log("cnpj: " + cnpj);
    const cnpjSemFormatacao = cnpj.replace(/\D/g, '');
  
    if (cnpjSemFormatacao.trim() === "" || cnpjSemFormatacao.length !== 14) {
      return;
    }
  
    setCnpj(cnpjSemFormatacao);
  }

  return (
    <Wrapper>
      {credenciais.length === 0 ? (
        <div className="notFound">
          <p className="info">
            Você não possui credenciais cadastradas. Crie uma credencial de API
            da Thomson Reuters para fazer o upload de arquivos.
          </p>
          <Button
            mt={16}
            mb={64}
            center
            onClick={() => history.push("/configuracoes#arquivei")}
          >
            Criar nova credencial
          </Button>
        </div>
      ) : (
        <div className="upload">
          <p className="step">
            1. Selecione a credencial que deseja utilizar para fazer o upload.
          </p>
          <Dropdown
            name="select-credencial"
            label="Credencial Thomson Reuters"
            className="dropdown"
            placeholder="Selecione uma credencial"
            onChange={(selectedOption) => setSelectedCredencial(selectedOption)}
            options={credenciais.map((credencial) => ({
              value: credencial.nome,
              label: credencial.nome,
            }))}
            isLoading={isLoading}
            noOptionsMessage={() => "Nenhuma credencial encontrado"}
          />

          <>
            <StyledInputMask
              mask="99.999.999/9999-99"
              name="select-cnpj"
              placeholder="Insira o CNPJ"
              onChange={(e) => handleSetCnpj(e.target.value)}
            />
          </>
          <>
            <StyledInputMask
              name="select_ie"
              placeholder="Insira a inscrição estadual"
              onChange={(e) => setIe(e.target.value)}
            />
          </>

          <p className="step">
            2. Selecione a data que os arquivos foram incluídos na Thomson Reuters
          </p>
          <DateDiv>
            <DateWrapper>
              <label>
                Data Início
                <input
                  name={`dtIni`}
                  type="date"
                  onChange={(e) => handleDateChange(e)}
                />
              </label>
            </DateWrapper>
            <DateWrapper>
              <label>
                Data Fim
                <input
                  max={moment().format("YYYY-MM-DD")}
                  name={`dtFim`}
                  type="date"
                  onChange={(e) => handleDateChange(e)}
                />
              </label>
            </DateWrapper>
          </DateDiv>
          <Button
            mt={48}
            onClick={() => {
              if (selectedCredencial && dataIni && dataFim && cnpj && ie) {
                carregarArquivos();
              }
            }}
          >
            Importar arquivos
          </Button>
        </div>
      )}
      <p className="legenda">
        Arquivos aceitos pela API da Thomson Reuters{" "}
        <b>NFe Entrada e NFe Saída.</b>
      </p>
    </Wrapper>
  );
};

export default UploadComThomson;
