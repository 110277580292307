import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import configData from "../../config.json";

import Modal from "../Modal";
import GAuthInstalacao from "./GAuthInstalacao";
import GAuthSincronizacao from "./GAuthSincronizacao";
import GAuthAtivacao from "./GAuthAtivacao";
import DesativaTwofactor from "./DesativaTwofactor";
import MudarSenha from "./MudarSenha";
import TwoFactor from "./TwoFactor";
import ResetBD from "./ResetBD";

import Select from "react-select";

import Button from "../Button";
import ProgressBar from "../ProgressBar";
import DSButton from "../@DesignSystem/Button";
import InputNumber from "../@DesignSystem/InputNumber";

// import Dropdown from "../@DesignSystem/Dropdown";
// import Button from "../@DesignSystem/Button";
// import ProgressBar from "../@DesignSystem/ProgressBar";

import { store } from "react-notifications-component";
import "animate.css";
import {
  ToastNotification,
  notificationTopRight,
  notificationTopCenter,
} from "../ToastNotification";

import { Link } from "react-scroll";

import BancoService from "../../services/BancoService";

import CriarUsuario from "./CriarUsuario";
import AlteraBancoPermissao from "./AlteraBancoPermissao";
import LoadingIcon from "../LoadingIcon";

import UserContext from "../../context/user-context";
import ArquivosService from "../../services/ArquivosService";
import UsuarioService from "../../services/UsuarioService";

import BancoContext from "../../context/banco-context";
import SwitchButton from "../SwitchButton";
import CertificadoDigital from "./CertificadoDigital";
import { useLocation } from "react-router-dom";
import * as msal from "@azure/msal-browser";
import VendaService from "../../services/VendaService";
import Agendamento from "./Agendamento";
import NotasAtt from "./NotasAtt";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import Arquivei from "./Arquivei";
import Historico from "./Historico";

const msalConfig = {
  auth: {
    clientId: configData.masal_config.auth.clientId,
    authority: configData.masal_config.auth.authority,
    redirectUri: process.env.REACT_APP_REDIRECT_URI_SSO,
  },
  cache: {
    cacheLocation: configData.masal_config.cache.cacheLocation, // This configures where your cache will be stored
    storeAuthStateInCookie:
      configData.masal_config.cache.storeAuthStateInCookie, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
const loginRequest = {
  scopes: configData.masal_config.loginRequest.scopes,
};
const apiConfig = {
  scopes: configData.masal_config.apiConfig.scopes, // e.g. ["scp1", "scp2"]
};
const tokenRequest = {
  scopes: [...apiConfig.scopes],
};
const myMSALObj = new msal.PublicClientApplication(msalConfig);

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
`;

const Section = styled.section`
  margin-top: 6rem;
  border: 1px solid ${(props) => props.theme.grayLight};
  background: ${(props) => props.theme.branco};
  padding: 3rem 4rem;
  border-radius: 8px;
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.1);

  &:first-of-type {
    margin-top: 2rem;
  }

  h3 {
    font-size: 2.375rem;
    margin-bottom: 2rem;
  }
`;

const SidenavConfig = styled.div`
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  top: 56px;
  width: 320px;
  height: calc(100vh - 56px);
  border-right: 1px solid #d9dee0;
  flex-grow: 0;
  margin: -40px 4rem -40px -40px;
  padding: 0;

  @media (max-width: 1440px) {
    width: 240px;
  }

  h5 {
    font-size: 1.75rem;
    margin: 2.25rem 2rem 1.25rem;
  }

  a {
    padding: 1rem 2rem;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  a:hover {
    text-decoration: underline;
  }

  a.active {
    background: ${(props) => props.theme.grayLight};
    text-decoration: underline;
  }
`;

const ContentConfig = styled.div`
  flex-grow: 1;
  .title {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    .switch-box {
      display: flex;
      align-items: center;
      p {
        font-size: 1.25rem;
        margin-right: 0.5rem;
        font-weight: 400;
      }
    }
  }
`;

const StyledH2 = styled.h2`
  font-size: 3.125rem;
  margin-bottom: -2rem;
`;

const LoadingBox = styled.div`
  position: absolute;
  padding-left: 8rem;
  padding-top: 6rem;
  height: 14rem;
  width: 20rem;
  background-color: rgba(255, 255, 255, 0.5);
  svg {
    width: 4rem;
  }
`;

const ModalReset = styled.div`
  h2 {
    font-size: 1.5em;
  }

  p {
    margin-top: 1em;
    font-size: 1.25em;
  }

  .buttons {
    margin-top: 2rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;

const ModalGAuth = styled.div`
  text-align: center;

  h2 {
    font-size: 2em;
    font-family: "DIN W01 Medium", "Segoe UI", "Verdana", sans-serif;
    font-weight: 500;
  }

  p {
    font-size: 1.25em;
    margin-top: 1em;
  }

  .gauth-logo {
    width: 70%;
    margin-top: 2em;
    margin-bottom: 1em;
  }

  .footer {
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
  }

  img.print {
    width: 70%;
    margin-top: 1em;
  }

  p.codigo {
    margin: 0 -2rem;
    background: #e0e0e0;
    border-radius: 3px;
    font-size: 0.85rem;
    text-transform: uppercase;
    display: inline-block;
    padding: 0.5em 0.75em;
    font-weight: 600;
    box-sizing: border-box;
  }

  p.dica {
    font-size: 0.9em;
    color: #333;
  }

  .inputCodigo {
    padding: 0.5em;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid lightgray;
    font-size: 1em;
    transition: all 0.3s ease;
  }
`;

const UsoBanco = styled.div`
  width: 460px;
  margin-top: 1rem;
  margin-bottom: 3rem;

  h5 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  h6 {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }

  div.progress {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    gap: 0.5rem;
  }
`;

const InfosVenda = styled.div`
  padding: 16px 0;
  box-sizing: border-box;

  p {
    font-size: 20px;
  }
`;

const ContrataMarketplace = styled.div`
  background: #ffffff;
  border: 1px solid #eceff4;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 24px;
  margin-top: 32px;
  width: 500px;

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  }

  h5 {
    font-size: 24px;
  }
`;

const LinkBlock = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: 6rem;

  .block {
    background: ${(props) => props.theme.branco};
    border: 1px solid ${(props) => props.theme.grayLight};
    padding: 2rem 2.5rem;
    border-radius: 8px;
    text-decoration: none;
    transition: all 200ms ease;

    &:hover {
      border: 1px solid ${(props) => props.theme.grayLight};
      background: ${(props) => props.theme.branco};
      box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.1);
    }

    h3 {
      font-size: 1.25rem;
      font-weight: 600;
      color: ${(props) => props.theme.text};
      text-decoration: none;
    }
  }
`;
export function Configuracoes(props) {
  const [isContato, setIsContato] = useState(false);
  const [twofactor, setTwofactor] = useState(false);
  const [isUsuarioSSO, setUsuarioSSO] = useState(true);
  const [gAuth, setGAuth] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [gAuthStep, setGAuthStep] = useState(0);
  const [qrCodeSrc, setQrCodeSrc] = useState("");
  const [codigoAlternativo, setCodigoAlternativo] = useState("");
  const [codeGauthValido, setCodeGauthValido] = useState(false);
  const [codeGauthInvalido, setCodeGauthInvalido] = useState(false);
  const [codeGauthIncorreto, setCodeGauthIncorreto] = useState(false);
  const [tamanhoOcupadoStorage, setTamanhoOcupadoStorage] = useState(0);
  const [tamanhoTotalStorage, setTamanhoTotalStorage] = useState(1);
  const [tamanhoOcupadoBanco, setTamanhoOcupadoBanco] = useState(0);
  const [tamanhoTotalBanco, setTamanhoTotalBanco] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBanco, setIsLoadingBanco] = useState(false);
  const [temCertificado, setTemCertificado] = useState(false);
  const [selectedBanco, setSelectedBanco] = useState({
    value: "todos",
    label: "Todos",
  });
  const [verTodos, setVerTodos] = useState(false);
  const [bancos, setBancos] = useState({});
  const [bancoOptions, setBancoOptions] = useState({});
  const [senhaIncorreta, setSenhaIncorreta] = useState(false);
  const [msalValidado, setMsalValidado] = useState(false);
  const [erroMsal, setErroMsal] = useState(false);
  const [tokenMsal, setTokenMsal] = useState("");
  const [compraMarketplace, setCompraMarketplace] = useState(false);
  const [capacidadeAdicional, setCapacidadeAdicional] = useState(10);
  const [usuariosAdicional, setUsuariosAdicional] = useState(1);
  const [contratarCapacidade, setContratarCapacidade] = useState(false);
  const [contratarUsuarios, setContratarUsuarios] = useState(false);
  const [qtdUsuarios, setQtdUsuarios] = useState(0);
  const [qtdUsuariosMaximo, setQtdUsuariosMaximo] = useState(0);
  const [redimensionaCapacidade, setRedimensionaCapacidade] = useState(false);
  const [redimensionaUsuarios, setRedimensionaUsuarios] = useState(false);
  const [isLoadingMP, setIsLoadingMP] = useState(false);
  const [certificadosCadastrados, setCertificadosCadastrados] = useState([]);
  const [loadingToken, setLoadingToken] = useState(false);

  const location = useLocation();

  useEffect(() => {
    verifica2factor();
    getOcupacaoMaximaBanco();
    verificaSSOUsuario();
    verificaVendaMarketplace();
  }, []);

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  /*************************MARKETPLACE*********************/
  const verificaVendaMarketplace = () => {
    VendaService.verificaVendaSSO(props.tokenLogin).then((resp) => {
      if (resp.data.marketplace) {
        setCompraMarketplace(true);
        setQtdUsuariosMaximo(resp.data.limite_usuarios);
        setQtdUsuarios(resp.data.qtd_usuarios);
        setRedimensionaCapacidade(resp.data.redimensiona_capacidade);
        setRedimensionaUsuarios(resp.data.redimensiona_usuarios);
      } else {
        setCompraMarketplace(false);
      }
    });
  };
  /*************************FIM MARKETPLACE*********************/

  /*************************SSO*****************************/
  const validaMsal = () => {
    myMSALObj
      .loginPopup(loginRequest)
      .then(handleResponseMsal)
      .catch((error) => {
        console.error(error);
      });
  };

  const handleResponseMsal = (response) => {
    //console.log(response);

    if (response !== null) {
      passTokenToVerifica(response);
    } else {
      passTokenToVerifica(selectAccount());
    }
  };

  const selectAccount = () => {
    const currentAccounts = myMSALObj.getAllAccounts();

    if (!currentAccounts || currentAccounts.length < 1) {
      return;
    } else if (currentAccounts.length > 1) {
      console.warn("Multiple accounts detected.");
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }
  };

  const passTokenToVerifica = (msalParams) => {
    getTokenPopup(tokenRequest, msalParams.account.username).then(
      (response) => {
        if (response) {
          //console.log("access_token acquired at: " + new Date().toString());
          UsuarioService.validarMsal(response.accessToken, props.tokenLogin)
            .then((res) => {
              if (res.status == 200) {
                setMsalValidado(true);
                setTokenMsal(response.accessToken);
                setErroMsal(false);
                //console.log("access_token: "+response.accessToken);
              } else {
                setMsalValidado(false);
                setTokenMsal("");
                setErroMsal(true);
              }
            })
            .catch((err) => {
              //console.log("Erro msal");
              //console.log(err);
              setMsalValidado(false);
              setTokenMsal("");
              setErroMsal(true);
            });
        }
      }
    );
  };

  const getTokenPopup = (request, username) => {
    request.account = myMSALObj.getAccountByUsername(username);

    return myMSALObj.acquireTokenSilent(request).catch((error) => {
      console.warn(error);
      console.warn(
        "silent token acquisition fails. acquiring token using popup"
      );
      if (error instanceof msal.InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return myMSALObj
          .acquireTokenPopup(request)
          .then((response) => {
            //console.log(response);
            return response;
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.warn(error);
      }
    });
  };

  /***********************FIM SSO*****************************/

  const getOcupacaoMaximaBanco = () => {
    setIsLoading(true);

    ArquivosService.getOcupacaoBanco(props.tokenLogin)
      .then((resp) => {
        console.log(resp);
        setTamanhoOcupadoBanco((resp.data.ocupacao / 1024).toFixed(2));
        setTamanhoTotalBanco((resp.data.capacidade_db / 1024).toFixed(0));

        ArquivosService.getOcupacaoStorage(props.tokenLogin)
          .then((resp2) => {
            console.log(resp2);
            setTamanhoOcupadoStorage((resp2.data.ocupacao / 1024).toFixed(2));
            setTamanhoTotalStorage(
              (resp2.data.capacidade_storage / 1024).toFixed(0)
            );
            if (!resp.data.bancos) {
              setIsLoadingBanco(false);
              return;
            }
            // OBJ BANCO COM OCUPAÇÃO STORAGE E BD DE CADA UM
            let bancos = {
              todos: {
                ocupacao_db: (resp.data.ocupacao / 1024).toFixed(2),
                ocupacao_storage: (resp2.data.ocupacao / 1024).toFixed(2),
                nome_banco: "Todos",
              },
            };

            // faz merge das 2 listas, de storage e de banco
            let bancoOptions = resp.data.bancos.map((banco) => {
              let banco_storage = resp2.data.bancos.find(
                (b2) => b2.id_banco === banco.id_banco
              );

              bancos[banco.id_banco] = {
                ocupacao_db: (banco.ocupacao / 1024).toFixed(2),
                ocupacao_storage: (banco_storage.ocupacao / 1024).toFixed(2),
                nome_banco: banco.nome_banco,
              };

              let objBanco = {
                value: banco.id_banco,
                label: banco.nome_banco,
              };
              return objBanco;
            });

            setBancos(bancos);
            setBancoOptions(bancoOptions);
            setIsLoadingBanco(false);
            setIsLoading(false);

            // console.log(resp.data);
          })
          .catch((err) => console.warn(err));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const verificaSSOUsuario = () => {
    UsuarioService.verificaSso(props.tokenLogin).then((resp) => {
      if (resp.data.sso) {
        setUsuarioSSO(true);
      } else {
        setUsuarioSSO(false);
      }
    });
  };

  const handleTwoFactorChange = (e) => {
    if (gAuth && twofactor) {
      setModalContent("gauth");
      toggleModal();
    } else {
      setTwofactor(e.target.checked);
    }
  };

  const handleGAuthChange = (e) => {
    setModalContent("gauth");
    if (!gAuth) {
      getQrcodeSrc();
      toggleModal();
    } else {
      toggleModal();
    }
  };

  useEffect(() => {
    if (verTodos) handleBancoChange({ label: "Todos", value: "todos" });
  }, [verTodos]);

  const handleChangeVerTodos = () => {
    setVerTodos(!verTodos);
  };

  const toggleModal = (e) => {
    setIsModalOpen(!isModalOpen);
    setCodeGauthValido(false);
    setCodeGauthIncorreto(false);
    setSenhaIncorreta(false);
    setGAuthStep(0);
  };

  const toggleModalReset = () => {
    setModalContent("reset");
    toggleModal();
  };

  const prevStep = () => {
    if (gAuthStep === 0) {
      toggleModal();
    } else {
      setGAuthStep(gAuthStep - 1);
    }
  };

  const nextStep = () => {
    if (gAuthStep >= 2) {
      toggleModal();
    }
    setGAuthStep(gAuthStep + 1);
    setCodeGauthIncorreto(false);
    setCodeGauthValido(false);
    setSenhaIncorreta(false);
  };

  const resetaBanco = () => {
    if (props.temOperacao) {
      store.addNotification({
        content: (
          <ToastNotification bg="secondary" textoAuxiliar="">
            Você não pode resetar o Banco de dados agora porque já tem uma
            operação em andamento!{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopCenter,
      });
    } else {
      BancoService.resetarBanco(props.tokenLogin)
        .then((resp) => {
          store.addNotification({
            content: (
              <ToastNotification bg="danger" textoAuxiliar="">
                Resetando banco de dados...{" "}
              </ToastNotification>
            ),
            // onRemoval: (id, removedBy) => //console.log(removedBy),
            ...notificationTopRight,
          });
          toggleModalReset();
          //console.log(resp);
          props.checkOperacao();
        })
        .catch((err) => {
          let msg = err.response.data.descricao;
          store.addNotification({
            content: (
              <ToastNotification bg="danger" textoAuxiliar="">
                {msg ? msg : "Não foi possível resetar o banco de dados"}{" "}
              </ToastNotification>
            ),
            // onRemoval: (id, removedBy) => //console.log(removedBy),
            ...notificationTopRight,
          });
        });
    }
  };

  const getQrcodeSrc = () => {
    UsuarioService.getTwofactorQRCode(props.tokenLogin)
      .then((resp) => {
        setQrCodeSrc(resp.data.qrcode);
        setCodigoAlternativo(resp.data.code);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const verifica2factor = () => {
    UsuarioService.validarTokenLogin(props.tokenLogin)
      .then((resp) => {
        setTwofactor(resp.data.twofactor);
        setGAuth(resp.data.twofactor);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const verificaTokenGauth = (tokenGauth) => {
    UsuarioService.validaTokenTwofactor(
      props.tokenLogin,
      tokenGauth.replace(/\s+/g, "")
    )
      .then((resp) => {
        setCodeGauthValido(true);
        setCodeGauthIncorreto(false);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          let resp = err.response.data;
          if (resp.statusCode === 403) {
            setCodeGauthIncorreto(true);
            setCodeGauthInvalido(true);
            setCodeGauthValido(false);
          }
        }
      });
  };

  const handleBancoChange = (selectedBanco, e) => {
    setTamanhoOcupadoBanco(bancos[selectedBanco?.value]?.ocupacao_db);
    setTamanhoOcupadoStorage(bancos[selectedBanco?.value]?.ocupacao_storage);
    setSelectedBanco(selectedBanco);
  };

  const alteraTwofactor = (senha, tokenGauth) => {
    let token = tokenGauth || "";
    if (!isUsuarioSSO) {
      UsuarioService.alternarTwoFactor(
        props.tokenLogin,
        senha,
        token.replace(/\s+/g, "")
      )
        .then((resp) => {
          handleAlternaTwoFactor();
        })
        .catch((err) => {
          handleErroAlternaTwoFactor(err);
        });
    } else {
      UsuarioService.alternarTwoFactorMsal(
        props.tokenLogin,
        tokenMsal,
        token.replace(/\s+/g, "")
      )
        .then((resp) => {
          handleAlternaTwoFactor();
          setTokenMsal(null);
          setMsalValidado(false);
          setErroMsal(false);
        })
        .catch((err) => {
          handleErroAlternaTwoFactor(err);
        });
    }
  };

  const handleAlternaTwoFactor = () => {
    //console.log(resp);
    verifica2factor();
    toggleModal();
  };

  const handleErroAlternaTwoFactor = (err) => {
    if (err.response && err.response.data) {
      let resp = err.response.data;
      if (
        resp.descricao === "Usuário ou senha incorretos" ||
        resp.descricao === "Senha inválida"
      ) {
        setCodeGauthIncorreto(false);
        setSenhaIncorreta(true);
      } else if (
        resp.descricao === "Token não informado" ||
        resp.descricao === "Token incorreto"
      ) {
        setCodeGauthIncorreto(true);
        setSenhaIncorreta(false);
      }
    }
    // console.log(err.response);
  };

  // para renomear ou apagar banco lidar com os outros componentes que usam bancos tambem
  const atualizarComponentes = () => {
    setIsLoading(true);
    setIsLoading(false);

    // console.log("Atualizar componente ------------------------");
  };

  const handleContratarCapacidade = () => {
    if (isLoadingMP) return;
    setIsLoadingMP(true);
    VendaService.contratarCapacidade(props.tokenLogin, capacidadeAdicional)
      .then((resp) => {
        store.addNotification({
          content: (
            <ToastNotification bg="secondary" textoAuxiliar="">
              Capacidade adicional contratada com sucesso!
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopCenter,
        });

        setIsLoadingMP(false);
      })
      .catch((err) => {
        setIsLoadingMP(false);
        console.log(err);
        let msg;
        if (err.response.status == 409)
          msg =
            "Já há uma solicitação de contratação de capacidade adicional pendente!";
        else msg = "Erro ao contratar capacidade adicional!";

        store.addNotification({
          content: (
            <ToastNotification bg="danger" textoAuxiliar="">
              {msg}
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      });
  };

  const handleContratarUsuarios = () => {
    if (isLoadingMP) return;
    setIsLoadingMP(true);
    VendaService.contratarCapacidade(props.tokenLogin, usuariosAdicional)
      .then((resp) => {
        store.addNotification({
          content: (
            <ToastNotification bg="secondary" textoAuxiliar="">
              Usuários adicionais contratados com sucesso!
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopCenter,
        });

        setIsLoadingMP(false);
      })
      .catch((err) => {
        setIsLoadingMP(false);
        let msg;
        console.log(err);
        if (err.response.status == 409)
          msg =
            "Já há uma solicitação de contratação de usuário adicional pendente!";
        else msg = "Erro ao contratar usuários adicionais!";
        store.addNotification({
          content: (
            <ToastNotification bg="danger" textoAuxiliar="">
              {msg}
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      });
  };

  const reenviaToken = () => {
    if (loadingToken) return;

    setLoadingToken(true);
    UsuarioService.reenviaToken(props.tokenLogin)
      .then((resp) => {
        store.addNotification({
          content: (
            <ToastNotification bg="primary" textoAuxiliar="">
              Token reenviado com sucesso!
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      })
      .catch((err) => {
        store.addNotification({
          content: (
            <ToastNotification bg="danger" textoAuxiliar="">
              Erro ao reenviar token!
            </ToastNotification>
          ),
          // onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      })
      .finally(() => {
        setTimeout(() => {
          setLoadingToken(false);
        }, 2000);
      });
  };

  return (
    <UserContext.Consumer>
      {(permissao) => (
        <Wrapper>
          <SidenavConfig>
            <h5>Configuraçoes</h5>
            <Link
              activeClass="active"
              to="uso-de-dados"
              spy={true}
              smooth={true}
              offset={-300}
              duration={500}
            >
              Uso de dados
            </Link>
            {/* <Link
              activeClass="active"
              to="historico"
              spy={true}
              smooth={true}
              offset={permissao === "contato" ? -300 : -300}
              duration={500}
            >
              Histórico de operações
            </Link> */}
            <Link
              activeClass="active"
              to="seguranca"
              spy={true}
              smooth={true}
              offset={permissao === "contato" ? -300 : -300}
              duration={500}
            >
              Segurança da conta
            </Link>
            {permissao != "leitor" && (
              <>
                <Link
                  activeClass="active"
                  to="certificado"
                  spy={true}
                  smooth={true}
                  offset={permissao === "contato" ? -300 : -300}
                  duration={500}
                >
                  Certificado Digital
                </Link>
                <Link
                  activeClass="active"
                  to="agendamento"
                  spy={true}
                  smooth={true}
                  offset={permissao === "contato" ? -300 : -300}
                  duration={500}
                >
                  Agendamento de carga
                </Link>
              </>
            )}
            {permissao === "contato" && (
              <Link
                activeClass="active"
                to="administrador"
                spy={true}
                smooth={true}
                offset={-300}
                duration={500}
              >
                Administrador
              </Link>
            )}
            <Link
              activeClass="active"
              to="arquivei"
              spy={true}
              smooth={true}
              offset={-300}
              duration={500}
            >
              Integrações
            </Link>
          </SidenavConfig>
          <ContentConfig>
            <StyledH2>Configurações da conta</StyledH2>
            <LinkBlock>
              <NavLink to="/notas" className="block">
                <h3>Notas de atualização</h3>
              </NavLink>
              <a href="https://api.taxcelapp.com/docs/" className="block">
                <h3>Documentação API</h3>
              </a>
            </LinkBlock>
            <BancoContext.Consumer>
              {(usuarioBanco) => (
                <Section title="Uso de dados" id="uso-de-dados">
                  <div className="title">
                    <h3>Uso de dados</h3>
                    {permissao === "contato" && (
                      <div className="switch-box">
                        <p>Ver uso por banco</p>
                        <SwitchButton
                          id="vertodos"
                          checked={verTodos}
                          onChange={handleChangeVerTodos}
                          small
                        ></SwitchButton>
                      </div>
                    )}
                  </div>
                  {isLoadingBanco && (
                    <LoadingBox>
                      <LoadingIcon big></LoadingIcon>
                    </LoadingBox>
                  )}
                  <>
                    {permissao === "contato" && verTodos && (
                      <Select
                        name="select-bancos"
                        isSearchable={true}
                        value={selectedBanco}
                        onChange={handleBancoChange}
                        options={[
                          {
                            value: "todos",
                            label: "Todos",
                          },
                          ...bancoOptions,
                        ]}
                        isLoading={props.isLoadingBanco}
                        placeholder="Selecione uma base de dados"
                        noOptionsMessage={() => "Nenhum banco encontrado"}
                      ></Select>
                    )}
                    <UsoBanco>
                      <h6>
                        Arquivos carregados{" "}
                        {selectedBanco.value === "todos" ||
                        permissao !== "contato"
                          ? "em todos os bancos"
                          : `no banco ${selectedBanco.label}`}
                      </h6>
                      <div className="progress">
                        <ProgressBar
                          progress={(
                            (tamanhoOcupadoBanco / tamanhoTotalBanco) *
                            100
                          ).toFixed(2)}
                        />
                        <p>
                          <b>{tamanhoOcupadoBanco}GB</b> ocupados de{" "}
                          <b>{tamanhoTotalBanco}GB</b> totais
                        </p>
                      </div>
                    </UsoBanco>
                    <UsoBanco>
                      <h6>
                        Arquivos no storage{" "}
                        {selectedBanco.value === "todos" ||
                        permissao !== "contato"
                          ? "em todos os bancos"
                          : `no banco ${selectedBanco.label}`}
                      </h6>
                      <div className="progress">
                        <ProgressBar
                          progress={Math.min(
                            100,
                            (tamanhoOcupadoStorage / tamanhoTotalStorage) * 100
                          ).toFixed(2)}
                        />
                        <p>
                          <b>{tamanhoOcupadoStorage}GB</b> ocupados de{" "}
                          <b>{tamanhoTotalStorage}GB</b> totais
                        </p>
                      </div>
                    </UsoBanco>
                    {!props.isLeitor && usuarioBanco && !verTodos && (
                      <ResetBD handleClick={toggleModalReset} />
                    )}
                    {compraMarketplace &&
                      permissao === "contato" &&
                      redimensionaCapacidade && (
                        <>
                          {contratarCapacidade ? (
                            <DSButton
                              mt={32}
                              secondary
                              bg="primaryLight"
                              color="primary"
                              onClick={() => setContratarCapacidade(true)}
                            >
                              Contratar mais Capacidade
                            </DSButton>
                          ) : (
                            <ContrataMarketplace>
                              <h5>Adicionar capacidade</h5>
                              <InputNumber
                                label="Capacidade adicional em GB"
                                mt={24}
                                mb={48}
                                minValue={10}
                                step={10}
                                defaultValue={10}
                                handleChange={(value) =>
                                  setCapacidadeAdicional(value)
                                }
                              />
                              <p>
                                Valor adicional por mês: $
                                {(300 * capacidadeAdicional) / 10} (USD)
                              </p>
                              <div className="flex">
                                <DSButton onClick={handleContratarCapacidade}>
                                  {!isLoadingMP ? (
                                    `Contratar ${capacidadeAdicional}GB`
                                  ) : (
                                    <LoadingIcon fill="white" />
                                  )}
                                </DSButton>
                                <DSButton
                                  secondary
                                  bg="dangerLight"
                                  color="danger"
                                  onClick={() => setContratarCapacidade(false)}
                                >
                                  Cancelar
                                </DSButton>
                              </div>
                            </ContrataMarketplace>
                          )}
                        </>
                      )}
                  </>
                </Section>
              )}
            </BancoContext.Consumer>
            {/* <Section title="Histórico de operações" id="historico">
              <Historico tokenLogin={props.tokenLogin} />
            </Section> */}
            <Section title="Segurança da conta" id="seguranca">
              <h3>Segurança da conta</h3>
              <TwoFactor
                handleTwoFactorChange={handleTwoFactorChange}
                handleGAuthChange={handleGAuthChange}
                twofactor={twofactor}
                gAuth={gAuth}
              />
              <div>
                <h4
                  style={{
                    fontSize: "1.75rem",
                    marginBottom: "1rem",
                    marginTop: "3rem",
                  }}
                >
                  Token de segurança
                </h4>
                <DSButton onClick={reenviaToken}>
                  Reenviar token para email{" "}
                  {loadingToken && <LoadingIcon fill="white" />}
                </DSButton>
              </div>
              {!isUsuarioSSO && (
                <MudarSenha
                  usuario={props.usuario}
                  tokenLogin={props.tokenLogin}
                />
              )}
            </Section>
            {permissao !== "leitor" && (
              <>
                <Section title="Certificado" id="certificado">
                  <CertificadoDigital
                    bancos={bancoOptions}
                    isLoading={isLoadingBanco}
                    tokenLogin={props.tokenLogin}
                    permissao={permissao}
                    setCertificadosCadastrados={setCertificadosCadastrados}
                    certificadosCadastrados={certificadosCadastrados}
                  />
                </Section>
                <Section title="Agendamento" id="agendamento">
                  <Agendamento
                    bancos={bancoOptions}
                    isLoading={isLoadingBanco}
                    tokenLogin={props.tokenLogin}
                    permissao={permissao}
                    certificadosCadastrados={certificadosCadastrados}
                  />
                </Section>
              </>
            )}
            {permissao === "contato" && (
              <>
                <Section title="Administrador" id="administrador">
                  <h3>Administrar usuários</h3>
                  {compraMarketplace && redimensionaUsuarios && (
                    <>
                      <InfosVenda>
                        <p>
                          Quantidade de usuários: {qtdUsuarios} (máximo de{" "}
                          {qtdUsuariosMaximo})
                        </p>
                        {!contratarUsuarios ? (
                          <DSButton
                            secondary
                            bg="primaryLight"
                            color="primary"
                            mt={16}
                            onClick={() => setContratarUsuarios(true)}
                          >
                            Contratar mais usuários
                          </DSButton>
                        ) : (
                          <ContrataMarketplace>
                            <h5>Adicionar usuários</h5>
                            <InputNumber
                              label="Quantidade de usuários"
                              mt={24}
                              mb={48}
                              minValue={1}
                              step={1}
                              defaultValue={1}
                              handleChange={(value) =>
                                setUsuariosAdicional(value)
                              }
                            />
                            <p>
                              Valor adicional por mês: ${24 * usuariosAdicional}{" "}
                              (USD)
                            </p>
                            <div className="flex">
                              <DSButton onClick={handleContratarUsuarios}>
                                {!isLoadingMP ? (
                                  `Contratar ${usuariosAdicional} ${
                                    usuariosAdicional > 1
                                      ? "usuários"
                                      : "usuário"
                                  }`
                                ) : (
                                  <LoadingIcon fill="white" />
                                )}
                              </DSButton>
                              <DSButton
                                secondary
                                bg="dangerLight"
                                color="danger"
                                onClick={() => setContratarUsuarios(false)}
                              >
                                Cancelar
                              </DSButton>
                            </div>
                          </ContrataMarketplace>
                        )}
                      </InfosVenda>
                    </>
                  )}
                  <CriarUsuario
                    atualizar={atualizarComponentes}
                    tokenLogin={props.tokenLogin}
                    planoSynapse={props.planoSynapse}
                  />
                  {isLoading ? (
                    <></>
                  ) : (
                    <AlteraBancoPermissao tokenLogin={props.tokenLogin} />
                  )}
                </Section>
              </>
            )}

            {isModalOpen && (
              <Modal
                id="modal"
                isOpen={isModalOpen}
                onClose={toggleModal}
                modalSize="md"
                modalTipo={modalContent === "reset" ? "danger" : "default"}
              >
                {modalContent === "reset" ? (
                  <ModalReset className="box-body">
                    <h2>
                      Tem certeza de que deseja <u>resetar o banco de dados</u>{" "}
                      do seu usuário?
                    </h2>
                    <p>Todos os dados serão apagados e perdidos</p>
                    <div className="buttons">
                      <Button background="gray" onClick={toggleModal}>
                        Cancelar
                      </Button>
                      <Button background="danger" onClick={resetaBanco}>
                        Resetar
                      </Button>
                    </div>
                  </ModalReset>
                ) : (
                  <ModalGAuth>
                    {gAuth ? (
                      <DesativaTwofactor
                        desativaTwofactor={alteraTwofactor}
                        codeGauthIncorreto={codeGauthIncorreto}
                        senhaIncorreta={senhaIncorreta}
                        msalValidado={msalValidado}
                        erroMsal={erroMsal}
                        usuarioSso={isUsuarioSSO}
                        handleValidaocaoMsal={validaMsal}
                      />
                    ) : gAuthStep === 0 ? (
                      <>
                        <GAuthInstalacao
                          prevStep={prevStep}
                          nextStep={nextStep}
                        />
                      </>
                    ) : gAuthStep === 1 ? (
                      <>
                        <GAuthSincronizacao
                          qrCodeSrc={qrCodeSrc}
                          codigoAlternativo={codigoAlternativo}
                          prevStep={prevStep}
                          nextStep={nextStep}
                          tokenMsal={tokenMsal}
                        />
                      </>
                    ) : (
                      <>
                        <GAuthAtivacao
                          handleClick={verificaTokenGauth}
                          ativaTwofactor={alteraTwofactor}
                          handleValidaocaoMsal={validaMsal}
                          usuarioSso={isUsuarioSSO}
                          prevStep={prevStep}
                          handleClickCancel={toggleModal}
                          codeGauthValido={codeGauthValido}
                          codeGauthInvalido={codeGauthInvalido}
                          codeGauthIncorreto={codeGauthIncorreto}
                          senhaIncorreta={senhaIncorreta}
                          tokenMsal={tokenMsal}
                          msalValidado={msalValidado}
                          erroMsal={erroMsal}
                        />
                      </>
                    )}
                  </ModalGAuth>
                )}
              </Modal>
            )}
            <Section title="Arquivei" id="arquivei">
              <Arquivei
                isLoading={isLoadingBanco}
                tokenLogin={props.tokenLogin}
              />
            </Section>
          </ContentConfig>
        </Wrapper>
      )}
    </UserContext.Consumer>
  );
}
