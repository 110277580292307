import React, { Component, useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import fileDownload from "js-file-download";

import UserContext from "../../context/user-context";
import BancoContext from "../../context/banco-context";
import { FloatingButtons } from "../FloatingButtons";
import FloatButton from "../FloatButton";
import SwitchButton from "../SwitchButton";

import { PillButton, PillContainer } from "../../theme/GlobalStyle";
// import Button from "../Button";

// import PowerbiEmbedded from "react-powerbi";

import { models, Report } from "powerbi-client";

import { PowerBIEmbed } from "powerbi-client-react";

import { ReactComponent as RefreshIcon } from "../../icons/refresh-icon.svg";
import { ReactComponent as DownloadIcon } from "../../icons/download-icon.svg";
import { ReactComponent as FullscreenIcon } from "../../icons/fullscreen-icon.svg";

import { store } from "react-notifications-component";
import "animate.css";
import {
  ToastNotification,
  notificationTopRight,
  notificationTopCenter,
} from "../ToastNotification";
import LoadingIcon from "../LoadingIcon";
import ArquivosService from "../../services/ArquivosService";
import PowerBIService from "../../services/PowerBiService";
import InlineMessage from "../@DesignSystem/InlineMessage";
import Title from "../@DesignSystem/Title";
import Button from "../@DesignSystem/Button";
import Modal from "../Modal";
import { set } from "date-fns";
import { useInterval } from "../../hooks/useInterval";
import { reset } from "mixpanel-browser";

const StyledH1 = styled.h1`
  font-size: 32px;
  text-align: center;
  margin-top: 32px;
`;

const DashboardContainer = styled.div`
  h2 {
    font-size: 2.5rem;
  }
`;

const LoadingDashboard = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #00000030;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 5rem;
  }
`;

const IframeContainer = styled.div`
  margin-top: 1em;
  position: relative;
  width: 95%;
  height: 0;
  padding-top: 56.25%;
  background: ${(props) => props.theme.pbiDashBackground};

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    box-sizing: border-box;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .switch {
    margin-right: 12rem;
    display: flex;
    align-items: center;

    @media (max-width: 1440px) {
      margin-right: 8rem;
    }

    @media (max-width: 1280px) {
      margin-right: 4rem;
    }

    p {
      font-size: 1.25rem;
      margin-right: 1rem;
      font-weight: 600;
      /* color: ${(props) => props.theme.primary}; */
    }
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  button {
    align-self: flex-end;
  }
`;

const formatos = [
  {
    value: "pbix",
    titulo: "PowerBI Desktop (.pbix)",
  },
  {
    value: "pdf",
    titulo: "PDF (.pdf)",
  },
  {
    value: "pptx",
    titulo: "PowerPoint (.pptx)",
  },
];

const DashboardPage = (props) => {
  const [pbiId, setPbiId] = useState("");
  const [pbiUrl, setPbiUrl] = useState("");
  const [pbiToken, setPbiToken] = useState("");
  const [pegouCredenciais, setPegouCredenciais] = useState(false);
  const [editableReport, setEditableReport] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [semArquivosCarregados, setSemArquivosCarregados] = useState(true);
  const [isHome, setIsHome] = useState(true);
  const [templateSelecionado, setTemplateSelecionado] = useState("");
  const [templateSelecionadoNome, setTemplateSelecionadoNome] = useState("");
  const [templates, setTemplates] = useState([]);
  const [reportConfig, setReportConfig] = useState({});
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [ehEditavel, setEhEditavel] = useState(false);
  const [needRefresh, setNeedRefresh] = useState(true);
  const [formatoSelecionado, setFormatoSelecionado] = useState("pbix");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalInatividade, setModalInatividade] = useState(false);
  const [keyFrame, setKeyFrame] = useState(0);
  const [report, setReport] = useState(null);

  const dashRef = useRef(null);

  useEffect(() => {
    if (!props.temBanco) return;

    console.log("remount");

    setDashboardLoading(true);

    PowerBIService.getStatusCapacity(props.tokenLogin)
      .then((resp) => {
        if (resp.data.loading == true) {
          setTimeout(() => {
            getTemplates();
          }, 5000);
        } else {
          getTemplates();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [templateSelecionado]);

  const minutosEmMilissegundo = 60 * 1000 * 1;

  const notificaInatividade = () => {
    stopInterval();
    store.addNotification({
      content: (
        <ToastNotification
          bg="danger"
          textoAuxiliar="Clique aqui para continuar navegando."
        >
          Token do PowerBI expirando em breve...
        </ToastNotification>
      ),
      onRemoval: (id, removedBy) => {
        console.log(templateSelecionado);
        if (removedBy === "click") {
          console.log(templateSelecionado);
          getCredenciaisPowerBI();
          return;
        }
        setModalInatividade(true);
      },
      ...notificationTopRight,
    });
  };

  //timer para verificar inatividade
  let { resetInterval, stopInterval } = useInterval(
    notificaInatividade,
    15 * minutosEmMilissegundo
  );

  useEffect(() => {
    console.log("template selecionado: ", templateSelecionado);
  }, [templateSelecionado]);

  const renovaCredenciaisPowerBI = (id = templateSelecionado) => {
    PowerBIService.getStatusCapacity(props.tokenLogin).then((resp) => {
      console.log("sessão renovada");
    }).catch((err) => {
      console.log(err);
    });

    PowerBIService.getEmbedTokenPowerBi(props.tokenLogin, id)
      .then((resp) => {
        //resp.data.report.token
        report.setAccessToken(resp.data.report.token);
      })
      .catch((err) => {
        console.log(err);
      });
    // .finally(() => {
    //   setDashboardLoading(false);
    // });
    setModalInatividade(false);
    resetInterval();
  }

  const getCredenciaisPowerBI = (id = templateSelecionado) => {
    setDashboardLoading(true);
    setModalInatividade(false);
    // report.setAccessToken("H4sIAAAAAAAEAC2Tt660aAJE3-WmrIRtzEp_gGu8_7AZ3jUeGjOad9-7o8lLFZw69dePnd6fKS1-_vuTTLzdOq_GXBRDE9aVgNapejIatulZWTwU0x7DbN-GPwj9mbw1HlOo_AhfnkaBZUKFuu7Cie389wadPRB8LnCHMftwjfUIrfeCByEEcX3M7sWDSkyPg6ATr80LEdj9R4ZrPNPTWPTWiM6HHjtHDOGDfq8urA49O1Qt4inWavJzMS4-4njQFyiclIiTRpJlAofpVZmDvtheIKgT5P4UBgxWHVLCZk3UuZBuUmV2YO11xz5n9L6QDjhqV7W_BUXUXSh4bO0hXZ1hEYai-bAryDmCGkXC10ucR8UHd3auz-O3qhfQ7EBWAb4LF97alybIpZKS9mncuyD4NhKyXHajCFqNu3sx4df0XTyS5oaew2TT3E44w72gPPhOZGTNINBNGoGE-CM6A2zniz8k7wdSGj9PFDg_Zou283yRm-hUjLTqgHjB0FXpIn9v-mzYT0hZK4K2eOSRTSI23Raq7zMcrv0qnDipVOmDByHuka9VxxHFkhcSMmkWJk7Fst7gmLmiLTHCPp0Fkod2pQPvq1NjnbrIIFd8ZFIDYWOKt2Ps7gEJ5pyaGkYsZC4-W93Nd74HF6c7bV2YVz5VMyJPELZ4uTftqlS7F5d1Mqlxueq0FkN2IiCfHdS3TzKgzoFIMqZzH1l83uCJU6t41ANkwGwo9v06v_6g3-37KJM4Snj-6dgItFjtONCpSVLzHn3kQGRxWjECf8e9sd2rmW0ZZ21Yz5N0zf758_OfH369533SyvtXc5HFtlhVapow5MEIonnUl-FK_FOKPqlrbaZp7hQ1KhLTo0ryK8rwtgMdQfjHD3-5qR7qrrh9dfVFnjUTQGx81dJCFTX2bG4TRV-O4pQc_-IwmSCB9YyLlqi0FrFaq_arDnAAZdJrRJllKTrhyaXy68iHPBkowMxv2g3sFR7Cox8k_HFGORU7z_5KruhrLddHWo1atTKGHzvLZTu6u1afs2xFCIth5unKXuRg9n14qV9PEutPaSjdzmu5d0GgVnxDaXWLYy17pA-09jvvip4xVB8z4Q5_1kj4JfGBhOVdc8cpdIw4qpsqNm_R1FghFK2ebdLwHZ4S91m-vNtPzGqY_G7R_2K-56ZcleCXsuaqv39vwuotf2c3O6C5R_n6n5TX1mO6H2v5G2Pnlpg6voFaJYJTUmFLvOEjki0nvWupBN4peUCgUxfeDwFeNQCakJCXk4VeU1gzR63e8C4zoCm6cVpWOUZK_tk_JYm0sz-VbEUvE1MxWYrshNNZVOaOfG4qsYN8fTXklqu321Mpjdehzuk3Ak7xCeYFKKeJgF5fxGy_q1tiXkh_VNeuqfg2FpV8NlnrLQeFVVs5Pw6IKd4qEdcjHInnBCKg7bSzxPDrwtJQxGR3MUUWrjf5egIUPrcOzYU1j3yJIEy2zC1ykALMLD2B56oEzgxe-5qkQ3a0GGkK2tziZ8bp7czmTBqW-Fb3SxGSzV07XIpqXYbonq88m6Ld5bLmEHv6_4_x9_8A6FsZR9oFAAA=.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUJSQVpJTC1TT1VUSC1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImV4cCI6MTc0MDU5NTYyNywiYWxsb3dBY2Nlc3NPdmVyUHVibGljSW50ZXJuZXQiOnRydWV9");

    // return;
    // console.log("get credenciais: ", id);

    PowerBIService.getEmbedTokenPowerBi(props.tokenLogin, id)
      .then((resp) => {
        setPbiId(resp.data.report.reportId);
        setPbiUrl(resp.data.report.embedUrl);
        setPbiToken(resp.data.report.token);
        setPegouCredenciais(true);
        setTimeout(() => {
          setDashboardLoading(false);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
    // .finally(() => {
    //   setDashboardLoading(false);
    // });

    resetInterval();
  };

  const refreshPbi = () => {
    if (props.temOperacao) {
      store.addNotification({
        content: (
          <ToastNotification bg="secondary" textoAuxiliar="">
            Você não pode atualizar o dashboard agora porque já tem uma operação
            em andamento!{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopCenter,
      });
    } else {
      //console.log("refresh");
      store.addNotification({
        content: (
          <ToastNotification>Atualizando dashboard... </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopRight,
      });
      //console.log(this.props.tokenLogin);
      PowerBIService.refreshPowerBI(props.tokenLogin)
        .then((resp) => {
          // console.log("refresh" + resp);
          props.checkOperacao();
        })
        .catch((err) => {
          console.log(err);
          let msg = err.response.data.descricao;
          store.addNotification({
            content: (
              <ToastNotification bg="secondary">
                {msg ? msg : "Não foi possível atualizar o Dashboard"}
              </ToastNotification>
            ),
            // onRemoval: (id, removedBy) => //console.log(removedBy),
            ...notificationTopRight,
          });
        });
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const dashFullscreen = () => {
    if (pegouCredenciais) {
      let iframe = document
        .querySelector(".report-style-class")
        .querySelector("iframe");
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      }
    }
  };

  const downloadDashboard = () => {
    let erro;
    if (props.isTeste && formatoSelecionado === "pbix") {
      erro =
        "No período de teste é permitido baixar o dashboard apenas em PDF ou PowerPoint";
    }

    if (downloading) {
      erro = "O dashboard(.pbix) já está sendo baixado. Aguarde...";
    }
    if (erro) {
      store.addNotification({
        content: (
          <ToastNotification bg="secondary" textoAuxiliar="">
            {erro}{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopRight,
      });
      return;
    }
    if (props.temOperacao) {
      store.addNotification({
        content: (
          <ToastNotification bg="secondary" textoAuxiliar="">
            Você não pode baixar o dashboard agora porque já tem uma operação em
            andamento!{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopRight,
      });
    } else {
      store.addNotification({
        content: (
          <ToastNotification textoAuxiliar="">
            Iniciando download do dashboard Aguarde...{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopRight,
      });

      setDownloading(true);

      let onDownload = (progressEvent) => {
        //  let progressoDownload = ((progressEvent.loaded/tamanho_arquivo) * 100).toFixed(0);
        // FAZ ALGUMA COISA COM ISSO?
        // console.log(progressoDownload);
      };
      PowerBIService.downloadDashboard(
        props.tokenLogin,
        onDownload,
        templateSelecionado,
        formatoSelecionado
      )
        .then((resp) => {
          //console.log(resp);
          fileDownload(resp.data, `dashboard.${formatoSelecionado}`);
          props.checkOperacao();
        })
        .catch(async (err) => {
          //console.log(err);
          let resObj = await err.response.data.text();
          let msg = "";
          try {
            msg = JSON.parse(resObj).descricao;
          } catch (e) {
            console.log(e);
          }
          store.addNotification({
            content: (
              <ToastNotification bg="danger">
                {msg
                  ? msg
                  : "Ocorreu um erro ao tentar fazer o download do .pbix"}{" "}
              </ToastNotification>
            ),
            // onRemoval: (id, removedBy) => //console.log(removedBy),
            ...notificationTopRight,
          });
        })
        .finally(() => {
          setDownloading(false);
        });
    }
  };

  const toggleEditReport = () => {
    setDashboardLoading(true);
    setEditableReport(!editableReport);
    setTimeout(() => {
      setDashboardLoading(false);
    }
      , 1000);
    if (!editableReport)
      store.addNotification({
        content: (
          <ToastNotification bg="primary">
            Lembre-se de salvar as alterações no dashboard antes de sair da
            página ou sair do modo de edição{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopRight,
      });


  };

  const getTemplates = () => {
    PowerBIService.getListaReports(props.tokenLogin)
      .then((resp) => {
        console.log(resp);

        let reports = resp.data.reports.map((rep) => {
          return {
            id: rep.nome,
            titulo: rep.nome.charAt(0).toUpperCase() + rep.nome.slice(1),
            nome: rep.nome,
            editavel: rep.editavel,
            atualizado: rep.atualizado,
          };
        });

        setTemplates(reports);

        if (templateSelecionado === "" || templateSelecionado === null) {
          setTemplateSelecionado(reports[0].id);
          setEhEditavel(reports[0].editavel);
          setNeedRefresh(!reports[0].atualizado);

          getCredenciaisPowerBI(reports[0].id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeTemplate = (id, editavel, atualizado) => {
    if (id === templateSelecionado) return;

    setTemplateSelecionado(id);
    setEhEditavel(editavel);
    setEditableReport(false);
    setNeedRefresh(!atualizado);

    getCredenciaisPowerBI(id);
  };

  return (
    <UserContext.Consumer>
      {(permissao) => (
        <BancoContext.Consumer>
          {(temBanco) =>
            !temBanco ? (
              <StyledH1>Você não tem banco vinculado à sua conta</StyledH1>
            ) : (
              <DashboardContainer>
                {modalInatividade && (
                  <Modal
                    id="modalInatividade"
                    isOpen={modalInatividade}
                    onClose={() => setModalInatividade(false)}
                    modalSize="md"
                    modalTipo="primary"
                    pgBackground="primary"
                  >
                    <ModalContainer>
                      <Title headingLevel="h3">Alerta de inatividade</Title>
                      <p>
                        Seu token de autorização do PowerBI expirou, clique no
                        botão abaixo para renová-lo e continuar navegando no
                        Dashboard
                      </p>
                      <Button
                        center
                        mt={32}
                        onClick={() => renovaCredenciaisPowerBI()}
                      >
                        Continuar no Dashboard
                      </Button>
                    </ModalContainer>
                  </Modal>
                )}
                {isModalOpen && (
                  <Modal
                    id="modalFormatoDash"
                    isOpen={isModalOpen}
                    onClose={toggleModal}
                    modalSize="lg"
                    modalTipo="primary"
                    pgBackground="primary"
                  >
                    <ModalContainer>
                      <Title headingLevel="h5">
                        Escolha um formato para baixar o dashboard:
                      </Title>
                      <PillContainer>
                        {formatos.map((formato, index) => (
                          <PillButton
                            key={index}
                            active={formatoSelecionado === formato.value}
                            onClick={() => {
                              setFormatoSelecionado(formato.value);
                            }}
                          >
                            {formato.titulo}
                          </PillButton>
                        ))}
                      </PillContainer>
                      <Button onClick={downloadDashboard}>
                        Baixar {formatoSelecionado}
                      </Button>
                    </ModalContainer>
                  </Modal>
                )}
                <Header>
                  <h2>PowerBI Dashboards</h2>
                  {!props.isLeitor && ehEditavel && (
                    <div className="switch">
                      <p>Dashboard editável</p>
                      <SwitchButton
                        id="editable-report"
                        onChange={toggleEditReport}
                        checked={editableReport}
                        small
                      />
                    </div>
                  )}
                </Header>
                {templates.length > 1 && (
                  <PillContainer>
                    {templates.map(({ id, titulo, editavel, atualizado }) => (
                      <PillButton
                        key={id}
                        active={templateSelecionado === id}
                        onClick={() => changeTemplate(id, editavel, atualizado)}
                      >
                        {titulo === "Branco" ? "Personalizado" : titulo}
                      </PillButton>
                    ))}
                  </PillContainer>
                )}
                {/* {this.state.needRefresh ? (
                  <InlineMessage type="system" iconColor="laranja">
                    É necessário atualizar o dashboard para sincronizar os
                    dados dos últimos arquivos carregados
                  </InlineMessage>
                ) : (
                  <InlineMessage type="success">
                    O dashboard já está sincronizado com os últimos arquivos
                    carregados
                  </InlineMessage>
                )} */}

                <IframeContainer className="pbi-iframe">
                  {pegouCredenciais && !dashboardLoading &&
                    (editableReport ? (
                      <PowerBIEmbed
                        key={keyFrame}
                        ref={dashRef}
                        id="editabledash"
                        embedConfig={{
                          type: "report", // Supported types: report, dashboard, tile, visual and qna
                          id: pbiId,
                          embedUrl: pbiUrl,
                          accessToken: pbiToken,
                          tokenType: models.TokenType.Embed,
                          permissions: models.Permissions.All,
                          viewMode: models.ViewMode.Edit,
                          settings: {
                            panes: {
                              filters: {
                                expanded: true,
                                visible: true,
                              },
                            },
                            // background: models.BackgroundType.Transparent,
                          },
                        }}
                        eventHandlers={
                          new Map([
                            [
                              "loaded",
                              function () {
                                console.log("editavel");
                                console.log("Report loaded");
                              },
                            ],
                            [
                              "rendered",
                              function () {
                                console.log("editavel");
                                console.log("Report rendered");
                              },
                            ],
                            [
                              "error",
                              function (event) {
                                console.log("editavel");
                                console.log("error report editable: ");
                                console.log(event.detail);

                                setKeyFrame(keyFrame + 1);
                              },
                            ],
                          ])
                        }
                        cssClassName={"report-style-class"}
                        getEmbeddedComponent={(embeddedReport) => {
                          window.report = embeddedReport; //as Report
                          setReport(embeddedReport);
                        }}
                      />
                    ) : (
                      <PowerBIEmbed
                        id="noneditabledash"
                        key={keyFrame}
                        embedConfig={{
                          type: "report", // Supported types: report, dashboard, tile, visual and qna
                          id: pbiId,
                          embedUrl: pbiUrl,
                          accessToken: pbiToken,
                          tokenType: models.TokenType.Embed,
                          // permissions: models.Permissions.All,
                          // viewMode: models.ViewMode.Edit,
                          settings: {
                            panes: {
                              filters: {
                                expanded: true,
                                visible: true,
                              },
                            },
                            // background: models.BackgroundType.Transparent,
                          },
                        }}
                        eventHandlers={
                          new Map([
                            [
                              "loaded",
                              function () {
                                console.log("normal");
                                console.log("Report loaded");
                              },
                            ],
                            [
                              "rendered",
                              function () {
                                console.log("normal");
                                console.log("Report rendered");
                              },
                            ],
                            [
                              "error",
                              function (event) {
                                console.log("normal");
                                console.log("error report non editable: ");
                                console.log(event.detail);

                                setKeyFrame(keyFrame + 1);
                              },
                            ],
                          ])
                        }
                        cssClassName={"report-style-class"}
                        getEmbeddedComponent={(embeddedReport) => {
                          window.report = embeddedReport; //as Report
                          setReport(embeddedReport);
                        }}
                      />
                    ))}
                  {dashboardLoading && (
                    <LoadingDashboard>
                      <LoadingIcon fill="white" />
                    </LoadingDashboard>
                  )}
                </IframeContainer>
                {/* <Button background="primary" onClick={this.toggleEditReport}>
        Editar Dashboard
      </Button> */}
                <FloatingButtons>
                  {!props.isLeitor && (
                    <FloatButton
                      background="primary"
                      big
                      texto="Atualizar dashboard"
                      onClick={refreshPbi}
                      new={false}
                    >
                      <RefreshIcon className="icon" />
                    </FloatButton>
                  )}
                  <FloatButton
                    white
                    background={downloading ? "gray" : "primary"}
                    texto={
                      downloading ? "Baixando dashboard" : "Baixar dashboard"
                    }
                    onClick={toggleModal}
                  >
                    {downloading ? (
                      <LoadingIcon big />
                    ) : (
                      <DownloadIcon className="icon" />
                    )}
                  </FloatButton>
                  <FloatButton
                    white
                    background="primary"
                    texto="Ver dashboard em tela cheia"
                    onClick={dashFullscreen}
                  >
                    <FullscreenIcon className="icon" />
                  </FloatButton>
                </FloatingButtons>
              </DashboardContainer>
            )
          }
        </BancoContext.Consumer>
      )}
    </UserContext.Consumer>
  );
};

export default DashboardPage;
