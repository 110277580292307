import React, { useState } from "react";
import { Wrapper, Button, ContentPatch, Box, BoxNav } from './style';

import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { ReactComponent as ChevronIcon } from "../../icons/Chevron.svg";

const Patch = () => {
  const [isCollapsed, setIsCollapsed] = useState({
    correcao: true,
    melhoria: true,
    novaFuncionalidade: true,
  });

  const patchData = {
    "Correção": [
      {
        title: "Recálculo D100",
        description: "Correção de BUG que impedia o recálculo D100 ser realizado.",
      },
      {
        title: "Recálculo D190",
        description: "Correção de BUG que impedia o recálculo D190 ser realizado.",
      },
    ],
    "Melhorias": [
      {
        title: "Layout atualizado",
        description: "Layout dos SPEDs atualizado para 2025.",
      },
      {
        title: "Melhoria no D100",
        description: "Possibilidade de recalcular registro D100 com base no registro D190.",
      },
    ],
    "Novas Funcionalidades": [
      {
        title: "Novos recálculos de arquivos CMS",
        description: "Possibilidade de recalcular registro D100 com base no registro D190.",
      },
      {
        title: "Nova funcionalidade XYZ",
        description: "Descrição da nova funcionalidade.",
      },
    ],
  };

  const toggleCollapse = (section) => {
    setIsCollapsed((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  return (
    <Wrapper>
    <div className="content">
      <div className="contentNav">
        <h1>Notas de atualização</h1>
      </div>
      <ContentPatch>
        <div className="contentTitle" >
          <span>22/02/2024 - 08:55</span>
          <h3>Versão 7.05 - Compilação 0703.0229</h3>
          <p>Correção, melhorias e novas funcionalidades</p>
        </div>

        {Object.entries(patchData).map(([key, notes]) => (
          <Box key={key}>
            <BoxNav>
              <h3 onClick={() => toggleCollapse(key)}>{key}</h3>
              <Button className="button" onClick={() => toggleCollapse(key)}>
                <ChevronIcon
                  style={{
                    transform: `rotate(${isCollapsed[key] ? 0 : 180}deg)`,
                    transition: "transform 0.3s ease-in-out",
                  }}
                />
              </Button>
            </BoxNav>
            {!isCollapsed[key] && (
              <>
                {notes.map((comment, index) => (
                  <div key={index}>
                    <span>{comment.title}</span>
                    <p>{comment.description}</p>
                  </div>
                ))}
              </>
            )}
          </Box>
        ))}
      </ContentPatch>
    </div>
  </Wrapper>
  );
};

export default Patch;