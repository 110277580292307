import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
  memo,
} from "react";

import {
  useTable,
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useSortBy,
  useExpanded,
  useGlobalFilter,
  useFilters,
  useRowSelect,
  useMountedLayoutEffect,
} from "react-table";

import { motion, AnimatePresence } from "framer-motion";

import { useHistory, useLocation, useParams } from "react-router-dom";

import MoreButton from "../MoreButton";

import { ReactComponent as BlockIcon } from "../../../icons/block.svg";
import { ReactComponent as DelayIcon } from "../../../icons/query_builder.svg";
import { ReactComponent as FirstPageIcon } from "../../../icons/first_page.svg";
import { ReactComponent as PreviousPageIcon } from "../../../icons/keyboard_arrow_left.svg";
import { ReactComponent as SortIcon } from "../../../icons/arrow_downward.svg";

import * as S from "./style";
import TableTooltip from "../TableTooltip";

import Select from "react-select";

import Button from "../Button";
import DSFormInput from "../FormInput";
import LoadingIcon from "../LoadingIcon";

import { useInput } from "../../../hooks/useInput";
import { useEffectDebugger } from "../../../hooks/useEffectDebugger";

const customStyles = {
  container: (provided, state) => ({ ...provided, width: 80 }),
  indicatorSeparator: () => ({ background: "none" }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: "0 12px 0 12px",
  }),
  menu: (provided, state) => ({ ...provided, borderRadius: "0 12px 0 12px" }),
  option: (provided, state) => ({ ...provided, borderRadius: "0 12px 0 12px" }),
};

const pageSizeOptions = [
  { value: 10, label: "10" },
  { value: 25, label: "25" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
  { value: 1000, label: "1000" },
];

const headerProps = (props, { column }) =>
  getStyles(props, column.align, column.stretch, column.isResizing);

const cellProps = (props, { cell }) =>
  getStyles(props, cell.column.align, cell.column.stretch);

const getStyles = (props, align = "left", stretch = 0, isResizing) => [
  props,
  {
    style: {
      justifyContent:
        align === "right"
          ? "flex-end"
          : align === "center"
          ? "center"
          : "flex-start",
      alignItems: "center",
      display: "flex",
      // flexGrow: isResizing ? 0 : stretch,
    },
  },
];

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Table({
  expiredRows,
  selectRows,
  expandRows,
  propsColumns,
  globalFilterValue,
  data,
  filterValue,
  setSelectedRows,
  csvButton,
  csvFilename,
  rowSubComponent,
  setnumberOfFilteredRows,
  noDataText,
  loading,
  serverPagination,
  fetchData,
  propDataError,
  pageCount: controlledPageCount,
  totalRowsCount,
  filtro,
  hiddenColumns = [],
}) {
  let history = useHistory();
  let query = useQuery();

  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const columns = React.useMemo(
    () => [
      ...(expiredRows
        ? [
            {
              Header: "",
              accessor: "expired",
              id: "expired",
              sortType: "basic",
              filter: "equals",
              disableResizing: true,
              width: 8,
              minWidth: 8,
              maxWidth: 8,
            },
          ]
        : []),
      ...(selectRows
        ? [
            {
              id: "selection",
              disableResizing: true,
              width: 32,
              minWidth: 32,
              maxWidth: 32,
              align: "center",
              class: "expand",
              Header: ({ getToggleAllPageRowsSelectedProps }) => (
                <div>
                  <IndeterminateCheckbox
                    {...getToggleAllPageRowsSelectedProps()}
                  />
                </div>
              ),
              Cell: ({ row }) => (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              ),
            },
          ]
        : []),
      ...(expandRows
        ? [
            {
              id: "expander",
              // Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
              //   <S.Expander
              //     expanded={isAllRowsExpanded}
              //     {...getToggleAllRowsExpandedProps()}
              //   >
              //     <PreviousPageIcon />
              //   </S.Expander>
              // ),
              Header: "",
              disableResizing: true,
              width: 32,
              minWidth: 32,
              maxWidth: 32,
              align: "center",
              class: "expand",
              Cell: ({ row }) => (
                <S.Expander
                  expanded={row.isExpanded}
                  {...row.getToggleRowExpandedProps()}
                >
                  <PreviousPageIcon />
                </S.Expander>
              ),
            },
          ]
        : []),
      ...propsColumns,
    ],
    [expandRows, expiredRows, propsColumns, selectRows]
  );

  const [tableHeight, setTableHeight] = useState("auto");
  const [dataError, setDataError] = useState(false);
  const [preFilterRows, setPreFilterRows] = useState(0);
  const tableElement = useRef(null);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setFilter,
    setGlobalFilter,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageIndex: parseInt(query.get("page")) || 0,
        hiddenColumns: hiddenColumns,
      },
      manualPagination: false,
      // pageCount: controlledPageCount,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useResizeColumns,
    useFlexLayout,
    useExpanded,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (!fetchData || !serverPagination) return;

    if (pageIndex * pageSize >= data.length) {
      console.log("fetch new data");
      fetchData({ pageIndex, pageSize });
    }

    if (pageIndex > 0 && data.length === 0) {
      setDataError(true);
    }
  }, [fetchData, pageIndex, pageSize, serverPagination]);

  useMountedLayoutEffect(() => {
    // console.log(rows);
    if (!setnumberOfFilteredRows) return;
    setnumberOfFilteredRows(rows.length);
  }, [rows]);

  useMountedLayoutEffect(() => {
    if (!selectRows) return;
    setSelectedRows(selectedFlatRows.map((row) => row.original));
  }, [selectedRowIds]);

  useMountedLayoutEffect(() => {
    setGlobalFilter(globalFilterValue || undefined);
  }, [globalFilterValue, setGlobalFilter]);

  useMountedLayoutEffect(() => {
    if (!filterValue) return;
    filterValue.map((filter) => setFilter(filter.id, filter.value));
  }, [filterValue, setFilter]);

  useEffect(() => {
    setTableHeight(tableElement.current.offsetHeight);
    setPreFilterRows(preGlobalFilteredRows.length);
  }, [preGlobalFilteredRows]);

  const renderRowSubComponent = React.useCallback(rowSubComponent, [
    rowSubComponent,
  ]);


  const baixarCSV = () => {
    
    if (!data.length) {
      alert("Não é possível baixar dados de uma tabela vazia");
      return;
    }

    let csvHeaders = Object.keys(data[0]).join(";");
    console.log(csvHeaders);

    const items = data;
    const filename = `${csvFilename}.csv` || `export.csv`;
    const d = `;`;

    const header = Array.from(
      new Set(items.reduce((r, e) => [...r, ...Object.keys(e)], []))
    );
    let csv = items.map((row) =>
      header.map((fieldName) => JSON.stringify(row[fieldName] || "")).join(d)
    );
    csv.unshift(header.join(d));
    csv = csv.join("\r\n");

    const blob = new Blob([csv], {
      type: "text/plain;charset=utf-8",
    });

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
      return;
    }

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = filename;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const canNextPagePagination = () => {
    if (!serverPagination) {
      if (!canNextPage) return false;
      return true;
    }

    if (pageSize * (pageIndex + 1) >= totalRowsCount) return false;

    return true;
  };

  return (
    <>
      <S.Container></S.Container>
      {/* <p>
        {selectedFlatRows.length} de {preGlobalFilteredRows.length}
      </p> */}
      <S.TableWrapper>
        <S.Table {...getTableProps()} ref={tableElement}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      className={column.class || ""}
                      {...column.getHeaderProps(
                        column.getSortByToggleProps(headerProps)
                      )}
                    >
                      {column.render("Header")}
                      {column.isSorted && (
                        <S.Sorter invert={column.isSortedDesc}>
                          <SortIcon />
                        </S.Sorter>
                      )}
                      {column.canResize && (
                        <S.Resizer
                          draggable={false}
                          {...column.getResizerProps()}
                          style={{ height: tableHeight }}
                          $active={column.isResizing}
                        />
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          {dataError || propDataError ? (
            <S.Loading>
              <p>Erro ao carregar dados</p>
              <Button
                center
                mt={16}
                onClick={() => {
                  console.log(pageIndex, pageSize);
                  history.push(`?page=0&size=${pageSize}`);
                  gotoPage(0);
                  fetchData(0, 1000);
                }}
              >
                Tentar novamente
              </Button>
            </S.Loading>
          ) : loading ? (
            <S.Loading>
              <LoadingIcon size="big" />
              <p>Carregando dados...</p>
            </S.Loading>
          ) : data.length ? (
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                /* console.log(row); */
                return (
                  <motion.tr
                    layout
                    key={row.id}
                    className={row.original.expired ? "expired" : ""}
                    {...row.getRowProps()}
                  >
                    <motion.div layout className="tr">
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps(cellProps)}
                            className={cell.column.class || ""}
                          >
                            <span className="cell-data">
                              {cell.render("Cell")}
                            </span>
                          </td>
                        );
                      })}
                    </motion.div>

                    <AnimatePresence>
                      {row.isExpanded && (
                        <S.ExpandedWrapper
                          $expired={row.original.expired}
                          initial={{ opacity: 0, scaleY: 0, y: "-100%" }}
                          animate={{ opacity: 1, scaleY: 1, y: 0 }}
                          exit={{ opacity: 0, scaleY: 0, y: "-50%" }}
                          transition={{ duration: 0.2 }}
                          layout
                          key="subRow"
                        >
                          <div className="block"></div>
                          {renderRowSubComponent({ row })}
                        </S.ExpandedWrapper>
                      )}
                    </AnimatePresence>
                  </motion.tr>
                );
              })}
            </tbody>
          ) : (
            <S.NenhumDado>
              <p>{noDataText ? noDataText : "Nenhum dado encontrado"}</p>
            </S.NenhumDado>
          )}
        </S.Table>
        <S.Pagination>
          <div className="qtdRows">
            <p>Linhas por página:</p>
            <Select
              placeholder={pageSize}
              onChange={(selectedOption) => setPageSize(selectedOption.value)}
              styles={customStyles}
              options={pageSizeOptions}
            />
          </div>
          <p className="pageInfo">
            {pageSize * pageIndex + 1} -{" "}
            {!canNextPage ? rows.length : pageSize * (pageIndex + 1)} de{" "}
            {serverPagination ? totalRowsCount : rows.length}
          </p>
          <div className="buttons">
            <div className="previous">
              {!serverPagination && (
                <button
                  onClick={() => gotoPage(0)}
                  className={!canPreviousPage ? "disabled" : ""}
                >
                  <FirstPageIcon />
                </button>
              )}
              <button
                onClick={() => {
                  console.log("previousPage", pageIndex);
                  history.push(
                    `?page=${parseInt(pageIndex) - 1}&size=${pageSize}`
                  );
                  gotoPage(parseInt(pageIndex) - 1);
                }}
                className={!canPreviousPage ? "disabled" : ""}
              >
                <PreviousPageIcon />
              </button>
            </div>
            <div className="next">
              <button
                onClick={() => {
                  console.log("nextPage", parseInt(pageIndex) + 1);
                  console.log(canNextPage);
                  if (!canNextPage) {
                    console.log("fetch");
                    if (!(pageSize * (pageIndex + 1) >= totalRowsCount)) {
                      fetchData({pageIndex: 0, pageSize: 1000, type: "nextPage", ...filtro});
                    }
                  }
                  history.push(
                    `?page=${parseInt(pageIndex) + 1}&size=${pageSize}`
                  );
                  gotoPage(parseInt(pageIndex) + 1);
                }}
                className={canNextPagePagination() ? "" : "disabled"}
              >
                <PreviousPageIcon />
              </button>
              {!serverPagination && (
                <button
                  onClick={() => gotoPage(pageCount - 1)}
                  className={!canNextPage ? "disabled" : ""}
                >
                  <FirstPageIcon />
                </button>
              )}
            </div>
          </div>
        </S.Pagination>
      </S.TableWrapper>
      {csvButton && data.length !== 0 && (
        <Button mt={16} ghost color="primary" onClick={baixarCSV}>
          Baixar csv da tabela
        </Button>
      )}
    </>
  );
}

export default memo(Table);
