import React from "react";
import * as S from "./style";

export default function Button({
  onClick,
  bg = "primary",
  color = "branco",
  iconLeft,
  iconRight,
  iconColor,
  mt,
  mb,
  size = "default",
  flat,
  fluid,
  fullWidth,
  children,
  ghost,
  center,
  secondary,
  fontSize,
  forwardRef,
  disabled, 
}) {
  return (
    <S.Button
      ref={forwardRef}
      onClick={onClick}
      $fontSize={fontSize}
      $bg={bg}
      $color={color}
      $iconColor={iconColor}
      $iconLeft={iconLeft}
      $iconRight={iconRight}
      $icon={iconLeft || iconRight}
      $fluid={fluid}
      $fullWidth={fullWidth}
      $center={center}
      $flat={flat}
      $secondary={secondary}
      $size={size}
      $ghost={ghost}
      $mt={mt !== "auto" ? `${mt}px` : mt}
      $mb={mb}
      disabled={disabled}
    >
      {children}
    </S.Button>
  );
}
