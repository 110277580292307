import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Title from "../@DesignSystem/Title";
import Dropdown from "../@DesignSystem/Dropdown";
import Button from "../@DesignSystem/Button";

import { scroller } from "react-scroll";

import { useHistory } from "react-router-dom";
import SwitchButton from "../@DesignSystem/SwitchButton";
import InputNumber from "../@DesignSystem/InputNumber";
import Radio from "../@DesignSystem/Radio";
import { cnpjMask } from "../../helpers/masks";
import AgendamentoService from "../../services/AgendamentoService";
import AgendamentoCriado from "./AgendamentoCriado";
import LoadingIcon from "../LoadingIcon";
import { store } from "react-notifications-component";
import { ToastNotification, notificationTopRight } from "../ToastNotification";

const Wrapper = styled.div`
  h3.title {
    font-size: 2.375rem;
    margin-bottom: 2rem;
  }
`;

const AreaProcurador = styled.div`
  margin-top: 32px;
`;

const DateWrapper = styled.div`
  margin-top: 32px;
  display: flex;

  label {
    display: block;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 2px;
    color: ${(p) => p.theme.text};
  }

  &:focus-within {
    label {
      color: ${(p) => p.theme.primary};
    }
  }

  input[type="date"],
  input[type="time"] {
    padding: 4px 8px;
    border-radius: 0 8px 0 8px;
    font-size: 16px;
    border: 2px solid ${(p) => p.theme.input.border};
    margin-left: 12px;

    &:active {
      border: 2px solid ${(p) => p.theme.primary};
      outline-color: ${(p) => p.theme.primary};
    }

    &:focus {
      border: 2px solid ${(p) => p.theme.primary};
      outline-color: ${(p) => p.theme.primary};
    }
  }

  input[type="time"] {
    margin-left: 16px;
  }
`;

const Frequencia = styled.div`
  display: flex;
  margin-top: 32px;
  align-items: center;

  p.text {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    margin-right: 12px;
  }

  .wrapper {
    width: 120px;
    margin-left: 12px;
  }
`;

const ErroMsg = styled.p`
  font-size: 12px;
  color: ${(p) => p.theme.danger};
  margin-top: 8px;
`;

export default function Agendamento({
  bancos,
  tokenLogin,
  permissao,
  certificadosCadastrados = [],
}) {
  const history = useHistory();

  const [certificadoSelecionado, setCertificadoSelecionado] = useState(null);
  const [isProcurador, setIsProcurador] = useState(false);
  const [docSelecionado, setDocSelecionado] = useState("");
  const [tipoArquivo, setTipoArquivo] = useState("");
  const [dataInicio, setDataInicio] = useState(
    new Date(new Date().setDate(new Date().getDate() + 1))
  );
  const [frequenciaQtd, setFrequenciaQtd] = useState(1);
  const [frequenciaTipo, setFrequenciaTipo] = useState("M");
  const [dataProxCarga, setDataProxCarga] = useState("");
  const [dtIniProxCarga, setDtIniProxCarga] = useState(""); 
  const [dtFimProxCarga, setDtFimProxCarga] = useState("");
  const [timeCarga, setTimeCarga] = useState("00:00");
  const [erroCertificado, setErroCertificado] = useState(false);
  const [erroProcurador, setErroProcurador] = useState(false);
  const [erroTipoArquivo, setErroTipoArquivo] = useState(false);
  const [erroData, setErroData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [agendamentos, setAgendamentos] = useState([]);

  const optSingular = [
    { label: "mês", value: "M" },
    { label: "ano", value: "Y" },
  ];

  const optPlural = [
    { label: "meses", value: "M" },
    { label: "anos", value: "Y" },
  ];

  useEffect(() => {
    atualizaAgendamentos();
  }, []);

  const atualizaAgendamentos = () => {
    AgendamentoService.getAgendamentos(tokenLogin).then((resp) => {
      setAgendamentos(
        resp.data.agendamentos.filter((agendamento) => agendamento.status === 1)
      );
      console.log(resp);
    });
  };

  const certificados = certificadosCadastrados.map((cert) => ({
    label: cert.nome,
    value: cert.id,
    ...cert,
  }));

  const formataData = (dataString) => {
    let data = new Date(dataString);
    let dia = data.getDate().toString();
    let diaF = dia.length == 1 ? "0" + dia : dia;
    let mes = (data.getMonth() + 1).toString(); //+1 pois no getMonth Janeiro começa com zero.
    let mesF = mes.length == 1 ? "0" + mes : mes;
    let anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
  };

  //data de hoje em yyy-mm-dd
  let dataHoje = new Date();
  dataHoje.setDate(dataHoje.getDate() + 1);
  dataHoje = dataHoje.toISOString().slice(0, 10);

  const criaAgendamento = () => {
    console.log({
      tokenLogin,
      isProcurador,
      num_doc: docSelecionado.length > 0,
      docSelecionado,
      nome_certificado: certificadoSelecionado?.label,
      tipoArquivo,
      data_agendamento: formataData(dataInicio) + " " + timeCarga,
      frequenciaTipo,
      frequenciaQtd,
    });

    if (isLoading) return;

    if (!certificadoSelecionado) {
      setErroCertificado(true);
      return;
    }
    if (isProcurador && !docSelecionado) {
      setErroProcurador(true);
      return;
    }
    if (!tipoArquivo) {
      setErroTipoArquivo(true);
      return;
    }
    let date = new Date();
    date.setHours(0, 0, 0, 0);

    if (dataInicio < date) {
      setErroData(true);
      return;
    }

    setIsLoading(true);

    AgendamentoService.criarAgendamento({
      tokenLogin,
      ehProcurador: isProcurador,
      ehCpf: docSelecionado?.length === 11,
      num_doc: docSelecionado,
      nome_certificado: certificadoSelecionado?.label,
      tipo: tipoArquivo,
      data_agendamento: formataData(dataInicio) + " " + timeCarga,
      periodo: frequenciaTipo,
      intervalo: frequenciaQtd,
    })
      .then((resp) => {
        console.log(resp);
        setIsLoading(false);

        store.addNotification({
          content: (
            <ToastNotification bg="primary" textoAuxiliar="">
              Agendamento criado!
            </ToastNotification>
          ),
          //onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });

        setCertificadoSelecionado(null);
        setIsProcurador(false);
        setDocSelecionado("");
        setTipoArquivo("");

        atualizaAgendamentos();
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        let errorMsg = err.response.status == 409 ? "Já existe um agendamento para este cerficado e tipo de arquivo informado" : "Erro ao criar agendamento!";
        store.addNotification({
          content: (
            <ToastNotification bg="laranja" textoAuxiliar="">
              {errorMsg}
            </ToastNotification>
          ),
          //onRemoval: (id, removedBy) => //console.log(removedBy),
          ...notificationTopRight,
        });
      });
  };

  useEffect(() => {
    console.log(dataInicio);
    if (frequenciaTipo === "M") {
      let date = new Date(
        dataInicio.getFullYear(),
        dataInicio.getMonth() - frequenciaQtd,
        dataInicio.getDate()
      );
      console.log(date);
      //seleciona o dia 1 do mês de date
      setDtIniProxCarga(formataData(new Date(date.getFullYear(), date.getMonth(), 1)));
      //Seleciona o ultimo dia do mês de date
      setDtFimProxCarga(formataData(new Date(date.getFullYear(), date.getMonth() + 1, 0)));
    } else {
      let date = new Date(
        dataInicio.getFullYear() - frequenciaQtd,
        dataInicio.getMonth(),
        dataInicio.getDate()
      );
      //Seleciona o primeiro dia do ano de date
      setDtIniProxCarga(formataData(new Date(date.getFullYear(), 0, 1)));
      //Seleciona o ultimo dia do ano de date
      setDtFimProxCarga(formataData(new Date(date.getFullYear(), 11, 31)));
    }
    
  }, [frequenciaQtd, frequenciaTipo, dataInicio]);

  return (
    <Wrapper>
      <h3 className="title">Agendamento</h3>
      <Title headingLevel="h5" mt={32}>
        Criar novo agendamento
      </Title>
      {certificados.length > 0 ? (
        <Dropdown
          className="zindexTop"
          placeholder="Seleciona o certificado"
          onChange={(selectedOption) => {
            setCertificadoSelecionado(selectedOption);
            setErroCertificado(false);
          }}
          options={certificados}
        />
      ) : (
        <>
          <p>É nencessário adicionar um certificado para criar agendamentos</p>
          <Button mt={16} onClick={() => history.push("#certificado")}>
            Ir para área de certificados
          </Button>
        </>
      )}
      {erroCertificado && <ErroMsg>Selecione um certificado</ErroMsg>}
      {certificadoSelecionado?.docs.length > 0 && (
        <AreaProcurador>
          <SwitchButton
            variant={1}
            selection={isProcurador ? 1 : 0}
            handleChange={(index) => setIsProcurador(index === 1)}
            options={["Contribuinte", "Procurador"]}
            size="small"
          />
          {isProcurador && (
            <>
              <Radio
                size="big"
                selected={0}
                mt={16}
                name="doc"
                handleChangeParent={(value) => {
                  setDocSelecionado(value);
                  setErroProcurador(false);
                }}
                options={certificadoSelecionado.docs.map((doc) => ({
                  label: cnpjMask(doc.num_doc),
                  value: doc.num_doc,
                }))}
              >
                CPF/CNPJ:
              </Radio>
              {erroProcurador && (
                <ErroMsg>
                  Selecione um documento para continuar como procurador
                </ErroMsg>
              )}
            </>
          )}
        </AreaProcurador>
      )}
      <Radio
        mt={32}
        selected={0}
        name="tipoArquivo"
        handleChangeParent={(value) => {
          setTipoArquivo(value);
          setErroTipoArquivo(false);
        }}
        options={[
          { label: "EFD ICMS", value: "ICMS" },
          { label: "EFD Contrib", value: "CONTRIB" },
          { label: "ECD Contábil", value: "CONTABIL" },
          { label: "ECF", value: "ECF" },
        ]}
      >
        Tipo de arquivo
      </Radio>
      {erroTipoArquivo && <ErroMsg>Selecione um tipo de arquivo</ErroMsg>}
      <DateWrapper>
        <label>
          Data e horário da carga
          <input
            name={`dtIni`}
            type="date"
            defaultValue={dataHoje}
            min={dataHoje}
            onChange={(e) => {
              setDataInicio(new Date(e.target.value + " 00:00"));
              if (e.target.value >= dataHoje) {
                setErroData(false);
              }
            }}
          />
        </label>
        <input
          name={`timeIni`}
          type="time"
          defaultValue="00:00"
          onChange={(e) => {
            setTimeCarga(e.target.value);
          }}
        />
      </DateWrapper>
      <p style={{ marginTop: 16 }}>
        Próximas cargas acontecerão no dia {dataInicio.getDate()} de todo mês
      </p>
      {erroData && (
        <ErroMsg>A data de início não pode ser anterior a data de hoje</ErroMsg>
      )}
      <Frequencia>
        <p className="text">Período retroativo: </p>
        <InputNumber
          small
          label=""
          minValue={1}
          step={1}
          defaultValue={1}
          handleChange={(value) => setFrequenciaQtd(value)}
        />
        <div className="wrapper">
          {frequenciaQtd === 1 ? (
            <Dropdown
              placeholder=""
              width={120}
              onChange={(selectedOption) =>
                setFrequenciaTipo(selectedOption.value)
              }
              value={frequenciaTipo === "M" ? optSingular[0] : optSingular[1]}
              options={optSingular}
            />
          ) : (
            <Dropdown
              placeholder=""
              width={120}
              onChange={(selectedOption) =>
                setFrequenciaTipo(selectedOption.value)
              }
              value={frequenciaTipo === "M" ? optPlural[0] : optPlural[1]}
              options={optPlural}
            />
          )}
        </div>
      </Frequencia>
      <p style={{ marginTop: 16 }}>
        Primeira carga será para o período: <b>{dtIniProxCarga + " até " + dtFimProxCarga}</b>
      </p>
      <Button mt={32} onClick={criaAgendamento}>
        Criar novo agendamento {isLoading && <LoadingIcon fill="white" />}
      </Button>
      <Title headingLevel="h5" mt={64}>
        Seus agendamentos
      </Title>
      {agendamentos.length > 0 ? (
        agendamentos.map((ag) => (
          <AgendamentoCriado
            key={ag._id}
            ag={ag}
            atualizaAgendamentos={atualizaAgendamentos}
            tokenLogin={tokenLogin}
          />
        ))
      ) : (
        <p>Você ainda não possui agendamentos</p>
      )}
    </Wrapper>
  );
}
