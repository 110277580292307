import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { set } from "date-fns";
import ArquiveiService from "../../services/ArquiveiService";
import ThomsonService from "../../services/ThomsonService";
import { useInput } from "../../hooks/useInput";
import FormInput from "../@DesignSystem/FormInput";
import Button from "../@DesignSystem/Button";

import { store } from "react-notifications-component";
import { notificationTopRight, ToastNotification } from "../ToastNotification";
import CredencialArquivei from "./CredencialArquivei";
import CredencialThomson from "./CredencialThomson";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 80px;

  .title {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 2.375rem;
      margin-bottom: 4px;
    }
    p {
      color: #6c757d;
      font-size: 14px;
      width: 450px;
    }
  }

  .novaCredencial {
    padding: 16px 20px;
    border: 1px solid #e9ecef;
    border-radius: 4px;
    margin-top: 64px;
    background-color: #f8f9fa;
    max-width: 400px;

    h4 {
      font-size: 1.25rem;
      margin-bottom: 32px;
      font-weight: 600;
    }
  }
`;

const WrapperBox = styled.div`
`;

const WrapperBoxTwo = styled.div`

.title {
  .paragraphy {
   width: 480px;
  }
}
  
`;

const Arquivei = ({ tokenLogin }) => {
  const [credenciais, setCredenciais] = useState([]);
  const [credenciaisThomson, setCredenciaisThomson] = useState([]);
  const {
    value: apiValue,
    bind: bindApiValue,
    reset: resetApiValue,
    check: checkApi,
    isInvalid: invalidApi,
  } = useInput("", "");
  const {
    value: nomeValue,
    bind: bindNomeValue,
    reset: resetNomeValue,
    check: checkNome,
    isInvalid: invalidNome,
  } = useInput("", "");
  const {
    value: chaveValue,
    bind: bindChaveValue,
    reset: resetChaveValue,
    check: checkChave,
    isInvalid: invalidChave,
  } = useInput("", "");
  const {
    value: usuarioThomsonValue,
    bind: bindUsuarioThomsonValue,
    reset: resetUsuarioThomsonValue,
    check: checkUsuarioThomson,
    isInvalid: invalidUsuarioThomson,
  } = useInput("", "");
  const {
    value: chaveThomsonValue,
    bind: bindChaveThomsonValue,
    reset: resetChaveThomsonValue,
    check: checkChaveThomson,
    isInvalid: invalidChaveThomson,
  } = useInput("", "");
  const {
    value: nomeThomsonValue,
    bind: bindNomeThomsonValue,
    reset: resetNomeThomsonValue,
    check: checkNomeThomson,
    isInvalid: invalidNomeThomson,
  } = useInput("", "");

  const getCredenciais = () => {
    // API call
    ArquiveiService.getCredenciais(tokenLogin)
      .then((response) => {
        console.log(response);
        setCredenciais(response.data.credenciais);
      })
      .catch((error) => {
        console.log(error);
        setCredenciais([]);
      });
    ThomsonService.getCredenciais(tokenLogin)
      .then((response) => {
        console.log(response);
        setCredenciaisThomson(response.data.credenciais);
      })
      .catch((error) => {
        console.log(error);
        setCredenciaisThomson([]);
      });
  };

  const criarCredencial = (tipo) => {
    // API call
    if(tipo === 'arquivei'){
      ArquiveiService.criarCredencial(tokenLogin, nomeValue, chaveValue, apiValue)
        .then((response) => {
          console.log(response);
          getCredenciais();
          resetApiValue();
          resetNomeValue();
          resetChaveValue();
          store.addNotification({
            ...notificationTopRight,
            content: (
              <ToastNotification bg="primary" textoAuxiliar="">
                Credencial nova cadastrada!
              </ToastNotification>
            ),
          });
        })
        .catch((error) => {
          console.log(error);
          store.addNotification({
            ...notificationTopRight,
            content: (
              <ToastNotification
                bg="danger"
                textoAuxiliar="Tente novamente mais tarde"
              >
                Erro ao cadastrar nova credencial
              </ToastNotification>
            ),
          });
        });
    } else if (tipo === 'thomson'){
      ThomsonService.criarCredencial(tokenLogin, nomeThomsonValue, chaveThomsonValue, usuarioThomsonValue)
        .then((response) => {
          console.log(response);
          getCredenciais();
          resetUsuarioThomsonValue();
          resetNomeThomsonValue();
          resetChaveThomsonValue();
          store.addNotification({
            ...notificationTopRight,
            content: (
              <ToastNotification bg="primary" textoAuxiliar="">
                Credencial nova cadastrada!
              </ToastNotification>
            ),
          });
        })
        .catch((error) => {
          console.log(error);
          store.addNotification({
            ...notificationTopRight,
            content: (
              <ToastNotification
                bg="danger"
                textoAuxiliar="Tente novamente mais tarde"
              >
                Erro ao cadastrar nova credencial
              </ToastNotification>
            ),
          });
        });
    }
  };

  useEffect(() => {
    getCredenciais();
  }, []);

  return (
    <Wrapper>
      <WrapperBox>
      <div className="title">
        <h3>Arquivei</h3>
        <p>
          A integração com a Arquivei permite que você tenha acesso a todas as
          suas notas fiscais de forma rápida e segura.
          Permite fazer upload de arquivos <b>NFe Entrada, NFe Saída, CFe, CTe e Evento NFe.</b>
        </p>
      </div>
      {credenciais.length === 0 ? (
        <p>
          Nenhuma credencial da Arquivei cadastrada. Crie uma nova credencial
          abaixo para importar arquivos.
        </p>
      ) : (
        credenciais.map((credencial) => (
          <CredencialArquivei credencial={credencial} tokenLogin={tokenLogin} getCredenciais={getCredenciais} />
        ))
      )}
      <div className="novaCredencial">
        <h4>Criar credencial</h4>
        <FormInput
          required
          mt={16}
          label="ID API"
          error={invalidApi}
          errorMsg=""
          {...bindApiValue}
        />
        <FormInput
          required
          mt={16}
          label="Chave"
          error={invalidChave}
          type="password"
          errorMsg=""
          {...bindChaveValue}
        />
        <FormInput
          required
          mt={16}
          label="Nome da credencial"
          error={invalidNome}
          errorMsg=""
          {...bindNomeValue}
        />
        <Button
          mt={32}
          onClick={() => {
            checkApi();
            checkNome();
            checkChave();
            if (
              !invalidApi &&
              !invalidNome &&
              !invalidChave &&
              apiValue &&
              nomeValue &&
              chaveValue
            ) {
              criarCredencial("arquivei");
            }
          }}
        >
          Criar credencial
        </Button>
      </div>
      </WrapperBox>
      <WrapperBoxTwo>
      <div className="title">
        <h3>Thomson Reuters</h3>
        <p className="paragraphy">
          Essa integração permite que você tenha acesso aos arquivos de nota fiscal da plataforma da Thomson Reuters.
          Permite fazer upload de arquivos <b>NFe Entrada, NFe Saída</b>
        </p>
      </div>
      {credenciais.length === 0 ? (
        <p>
          Nenhuma credencial da Thomson Reuters cadastrada. Crie uma nova credencial
          abaixo para importar arquivos.
        </p>
      ) : (
        credenciaisThomson.map((credencial) => (
          <CredencialThomson credencial={credencial} tokenLogin={tokenLogin} getCredenciais={getCredenciais} />
        ))
      )}
      <div className="novaCredencial">
        <h4>Criar credencial</h4>
        <FormInput
          required
          mt={16}
          label="USUARIO API"
          error={invalidUsuarioThomson}
          errorMsg=""
          {...bindUsuarioThomsonValue}
        />
        <FormInput
          required
          mt={16}
          label="Chave"
          error={invalidChaveThomson}
          type="password"
          errorMsg=""
          {...bindChaveThomsonValue}
        />
        <FormInput
          required
          mt={16}
          label="Nome da credencial"
          error={invalidNomeThomson}
          errorMsg=""
          {...bindNomeThomsonValue}
        />
        <Button
          mt={32}
          onClick={() => {
            checkUsuarioThomson();
            checkNomeThomson();
            checkChaveThomson();
            if (
              !invalidUsuarioThomson &&
              !invalidChaveThomson &&
              !invalidNomeThomson &&
              usuarioThomsonValue &&
              nomeThomsonValue &&
              chaveThomsonValue
            ) {
              criarCredencial("thomson");
            }
          }}
        >
          Criar credencial
        </Button>
      </div>
      </WrapperBoxTwo>
    </Wrapper>
  );
};

export default Arquivei;
